import { UserState, UserStateActions, useStore } from "../stores/dataStore";
import { api } from "../service/api";
import { deleteSessionStoragesAndReload, getBase64 } from "./funcs";
import Compressor from "compressorjs";
import randomBytes from "randombytes";
import moment from "moment";
import { Contact } from "@/types/Contact";
import { generateWhatsAppMsgId } from "./generateWhatsAppMsgId";
import { ETypeMessage, Message } from "../models/message.model";
import { socket } from "../service/socket";

function UserStore(): UserState & UserStateActions {
  return useStore() as any;
}
export type ChannelsType =
  | "whatsapp"
  | "telegram_bot"
  | "instagram"
  | "whatsapp_cloud";
export async function sendText(
  text: string,
  options: {
    replyData?: any;
    lastMsg?: Message;
    isGroupMsg?: boolean;
    contact_type?: ChannelsType;
    dateScheduled?: Date;
    msgId?: {
      html: string;
      msg: Message;
    };
    openChat?: boolean;
    contact: Contact;
  }
) {
  const MessageModel = new Message(
    {
      type: ETypeMessage.CHAT,
      content:
        getUserNameAddCaption(
          options.contact.contact_type,
          options.contact.Messages[
            options.contact?.Messages?.length - 1
          ] as any,
          UserStore().user.id
        ) + text,
      body:
        getUserNameAddCaption(
          options.contact.contact_type,
          options.contact.Messages[
            options.contact?.Messages?.length - 1
          ] as any,
          UserStore().user.id
        ) + text,
      dateScheduled: options.dateScheduled
        ? moment(options?.dateScheduled).toDate()
        : undefined,
      fromMe: true,
      userIdSend: UserStore().user.id,
      quotedMsgId: options.replyData?.msg?.msgId,
      quotedMsg: options?.replyData?.msg,
    },
    options.contact as any,
    typeof options.openChat === "boolean" ? options.openChat : true
  );
  // Send to server
  try {
    // Enviando mensagem através de socket
    /*socket.emit("message:send", {
      contact: options.contact,
      quotedMsg: options?.replyData?.msg?.messageSocket,
      user: UserStore().user.id,
      message: MessageModel,
    });*/
    const res = await api.post("message/send", {
      ...MessageModel,
      openChat: options.openChat,
      readKeys: options.contact?.Messages.map((i) => {
        return i.messageSocket?.key || undefined;
      }),
      reply: options?.replyData,
    });
    updateMessageDefault("chat", res);
  } catch (error) {
    updateMessageError(MessageModel.msgId);
    throw new Error("Erro ao enviar mensagem:\n" + error);
  }
}
export async function sendPtt(
  chatId: string,
  contactId: number,
  audio: File,
  options: {
    replyData?: any;
    lastMsg?: Message;
    isGroupMsg?: boolean;
    contact_type?: ChannelsType;
    duration?: number;
    dateScheduled?: Date;
    msgId?: any;
    contact?: Contact;
  }
) {
  let channelType = "whatsapp" as any;
  if (options.contact_type) channelType = options.contact_type as any;
  const json = getDefaultJSON(channelType, chatId, options.replyData, {
    msgId: options.msgId,
  });
  const formData = new FormData();
  formData.append("channelType", channelType);
  formData.append("id", json.id);
  formData.append("to", chatId);
  formData.append("chatId", chatId);
  formData.append("msgId", json.id);
  formData.append("contactId", contactId as any);
  formData.append("duration", `${options.duration}`);
  formData.append("file", audio, audio.name);
  formData.append(
    "readKeys",
    JSON.stringify(
      options.contact?.Messages.map((i) => {
        return i.messageSocket?.key || undefined;
      })
    )
  );
  if (options.replyData)
    formData.append("replyMsgId", options.replyData.msg.msgId);
  if (options.replyData)
    formData.append(
      "reply",
      options.replyData ? JSON.stringify(options!.replyData) : ""
    );
  if (options.dateScheduled)
    formData.append(
      "dateScheduled",
      `${moment(options?.dateScheduled).toDate()}`
    );

  const message = {
    ...json,
    type: "ptt",
    body: await getBase64(audio),
    path: await getBase64(audio),
    mimetype: "audio/mpeg",
    isGroupMsg: options.isGroupMsg,
    isScheduled: options.dateScheduled ? true : false,
  };
  editChatDefault(contactId);
  UserStore().addMessage(contactId as any, message);

  api
    .post("message/send-ptt", formData, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${
          (formData as any)._boundary
        }`,
      },
    })
    .then((res) => {
      updateMessageDefault("ptt", res);
    })
    .catch((err) => {
      throw new Error("Erro a enviar áudio\n" + err);
    });
}

export function sendFile(
  channelType: ChannelsType,
  channelId: string,
  type: string,
  chatId: string,
  contactId: number,
  file: File,
  caption: string,
  base64: string,
  replyData: any,
  isGroupMsg: boolean,
  options?: {
    dateScheduled?: Date;
    msgId?: any;
    contact?: Contact;
  },
  watermark?: any,
  watermarkData?: any
) {
  const json = getDefaultJSON(channelType, chatId, replyData, {
    msgId: options?.msgId,
  });
  const formData = new FormData();
  formData.append("message_type", channelType);
  formData.append("channelId", channelId as string);
  formData.append("id", json.id);
  formData.append("msgId", json.id);
  formData.append("type", type);
  formData.append("o", chatId);
  formData.append("caption", caption);
  formData.append("isGroupMsg", isGroupMsg as unknown as string);
  formData.append("chatId", chatId);
  formData.append("contactId", contactId as unknown as string);
  formData.append(
    "readKeys",
    JSON.stringify(
      options?.contact?.Messages.map((i) => {
        return i.messageSocket?.key || undefined;
      })
    )
  );
  if (replyData) {
    formData.append("replyMsgId", replyData.msg.msgId);
  }
  if (options?.dateScheduled) {
    formData.append(
      "dateScheduled",
      `${moment(options?.dateScheduled).toDate()}`
    );
  }

  const message = {
    ...json,
    content: caption,
    caption: caption,
    body: base64,
    path: base64,
    type: type,
    mimetype: file.type,
    filename: file.name,
    isGroupMsg: isGroupMsg,
    size: file.size,
    isScheduled: options?.dateScheduled ? true : false,
  };
  editChatDefault(contactId);
  UserStore().addMessage(contactId as any, message);

  if (file.type.includes("image")) {
    new Compressor(file, {
      quality: 0.6,
      drew(context, canvas) {
        if (watermark === true) {
          context.fillStyle = "#fff";
          context.font = "30em arial";
          context.textAlign = "center";
          context.strokeStyle = "rgba(0, 0, 0, 0.5)";
          context.lineWidth = 20;
          context.fillStyle = "rgba(255, 255, 255, 0.5)";
          context.strokeText(
            "Titan Chat",
            canvas.width / 2,
            canvas.height / 2,
            canvas.width
          );
          context.fillText(
            "Titan Chat",
            canvas.width / 2,
            canvas.height / 2,
            canvas.width
          );
        }
      },
      success(result) {
        formData.append("file", result, (result as any).name);
        api
          .post("message/send-image", formData)
          .then((res) => {
            updateMessageDefault("image", res);
          })
          .catch((err) => {
            throw new Error("Erro ao enviar mensagem:\n" + err);
          });
      },
    });
  } else {
    formData.append("file", file, file.name);
    api
      .post("message/send-file", formData)
      .then((res) => {
        updateMessageDefault("file", res);
      })
      .catch((err) => {
        throw new Error("Erro ao enviar mensagem:\n" + err);
      });
  }
}

export function sendContact(
  channelType: ChannelsType,
  chatId: string,
  contactId: number,
  contactName: string,
  contactNumber: number,
  replyData: any,
  isGroupMsg = false,
  dateScheduled: Date
) {
  const ctt = `BEGIN:VCARD
VERSION:3.0
N:;${contactName};;;
FN:${contactName};
X-WA-BIZ-NAME:${contactName};
TEL;type=CELL;type=VOICE;waid=${contactNumber}:${contactNumber}
END:VCARD`;
  const message = {
    ...getDefaultJSON(channelType, chatId, replyData),
    content: "📞 Contato",
    type: "vcard",
    body: ctt,
    contactsMessage: {
      name: contactName,
      type: "simple",
      contacts: [`${contactNumber}`],
    },
    isGroupMsg: isGroupMsg,
    nameCTT: contactName,
    cttId: `${contactNumber}@s.whatsapp.net`,
    contactId: contactId,
    dateScheduled: dateScheduled ? moment(dateScheduled).toDate() : undefined,
    isScheduled: dateScheduled ? true : false,
  };
  editChatDefault(contactId);
  UserStore().addMessage(contactId as any, message);

  // Send to server
  api
    .post("message/send-contact", message)
    .then((res) => {
      updateMessageDefault("vcard", res);
    })
    .catch((err) => {
      throw new Error("Erro:\n" + err);
    });
}
/**
 * Send Location Message to Customer
 * @param channelType
 * @param chatId
 * @param lat
 * @param lng
 * @param replyData
 */
export function sendLocation(
  channelType: ChannelsType,
  chatId: string,
  contactId: number,
  lng: number,
  lat: number,
  loc: string,
  opts?: {
    reply?: any;
    dateScheduled?: Date;
    msgId?: any;
  }
) {
  const message = {
    ...getDefaultJSON(channelType, chatId, opts?.reply, {
      msgId: opts?.msgId,
    }),
    content: loc || "",
    type: "location",
    lat: lat,
    lng: lng,
    loc: loc || "",
    body: loc || "",
    contactId: contactId,
    dateScheduled: opts?.dateScheduled
      ? moment(opts?.dateScheduled).toDate()
      : undefined,
    isScheduled: opts?.dateScheduled ? true : false,
  };
  editChatDefault(contactId);
  UserStore().addMessage(contactId as any, message);

  api
    .post("message/send-location", message)
    .then((res) => {
      updateMessageDefault("location", res);
    })
    .catch((err) => {
      throw new Error("Erro:\n" + err);
    });
}
/**
 * Send Buttons Message to Customer
 * @param channelType
 * @param channelId
 * @param chatId
 * @param lat
 * @param lng
 * @param replyData
 * @param isGroupMsg
 */
export function sendButtons(
  channelType: ChannelsType,
  channelId = 0,
  chatId: string,
  contactId: number,
  title: string,
  footer: string,
  content: string,
  buttons: any,
  replyData: any,
  isGroupMsg = false,
  options?: {
    dateScheduled?: Date;
    msgId?: any;
  }
) {
  const botoes = buttons;
  const format_buttons: any = [];
  botoes.map((btt: any) => {
    let newBT;
    if (btt.type == "text") {
      newBT = {
        id: btt.id,
        text: btt.text,
      };
      format_buttons.push(newBT);
    }
    if (btt.type == "url") {
      newBT = {
        id: btt.id,
        url: btt.url,
        text: btt.text,
      };
      format_buttons.push(newBT);
    }
    if (btt.type == "phone") {
      newBT = {
        id: btt.id,
        phoneNumber: btt.phoneNumber,
        text: btt.text,
      };
      format_buttons.push(newBT);
    }
    if (btt.type == "copy") {
      newBT = {
        id: btt.id,
        url: "https://www.whatsapp.com/otp/copy/" + btt.url,
        text: btt.text,
      };
      format_buttons.push(newBT);
    }
  });
  const message = {
    ...getDefaultJSON(channelType, chatId, replyData, {
      msgId: options?.msgId,
    }),
    content: content,
    type: "chat_buttons",
    body: footer,
    caption: title,
    title: title,
    footer: footer,
    list_sections: JSON.stringify(format_buttons),
    buttons: format_buttons,
    isGroupMsg: isGroupMsg,
    contactId: contactId,
    dateScheduled: options?.dateScheduled
      ? moment(options?.dateScheduled).toDate()
      : undefined,
    isScheduled: options?.dateScheduled ? true : false,
  };
  editChatDefault(contactId);
  UserStore().addMessage(contactId as any, message);

  // Send to server
  api
    .post("message/send-buttons", message)
    .then((res) => {
      updateMessageDefault("buttons", res);
    })
    .catch((err) => {
      throw new Error("Erro:\n" + err);
    });
}
export async function forwardMessages(
  channelType: ChannelsType,
  contactsIds: number[],
  chatsIds: string[],
  msgsIds: string[],
  msgs: any[],
  opts?: {
    openChat?: boolean;
    dateScheduled?: Date;
  }
) {
  const data = useStore();
  if (!chatsIds || !msgsIds) {
    return true;
  }
  data.forward_chatsId = [];
  data.forward_contactIds = [];
  data.forward_msgIds = [];
  data.forward_openChat = true;
  data.forward_messages = [];

  contactsIds?.forEach((contactId: number) => {
    if (opts?.openChat) {
      editChatDefault(contactId);
    }
  });
  try {
    const messages: any = [];
    chatsIds.forEach((chatId) => {
      msgs.forEach((msg) => {
        messages.push({ chatId, msg, id: generateWhatsAppMsgId("") });
      });
    });
    await api.post("message/forward/", {
      channelType,
      chatsIds,
      msgsIds,
      messages: messages as any,
      contactsIds,
      openChat: opts?.openChat || true,
      dateScheduled: opts?.dateScheduled
        ? moment(opts?.dateScheduled).toDate()
        : undefined,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function sendPoll(
  channelType: "whatsapp",
  chatId: string,
  contactId: number,
  name: string,
  values: string[],
  options?: {
    selectableCount?: number;
    dateScheduled?: Date;
    msgId?: any;
  }
) {
  try {
    const message = {
      ...getDefaultJSON(channelType, chatId, "", {
        msgId: options?.msgId,
      }),
      type: "poll",
      body: name,
      list_sections: values,
      contactId: contactId,
      selectableCount: options?.selectableCount,
      dateScheduled: options?.dateScheduled
        ? moment(options?.dateScheduled).toDate()
        : undefined,
      isScheduled: options?.dateScheduled ? true : false,
    };
    editChatDefault(contactId);
    UserStore().addMessage(contactId as any, message);

    const data = await api.post("message/send-poll", message);
    updateMessageDefault("buttons", data);
  } catch (error) {
    throw new Error("Erro:\n" + error);
  }
}

/**
 * Gera um novo ID para utilizar nas mensagens
 * @returns Return a unique id
 */
export function newId(to?: string): string {
  return "BAE5" + randomBytes(6).toString("hex").toUpperCase();
}
/**
 * Gera um padrão para enviar a mensagem,
 * com os dados de resposta, chatId, tipo de chat, etc
 *
 * @param {} channelType Tipo de canal 'whatsapp', telegram, facebook, etc
 * @param {*} chatId id do chat
 * @param {*} replyData data message to reply
 * @returns retorna um JSON com os dados todos carregados,
 * inclusive com os dados do usuário que está enviando a mensagem
 */
function getDefaultJSON(
  channelType: ChannelsType,
  chatId: string,
  replyData?: any,
  opts?: {
    msgId?: any;
  }
): any {
  const data = useStore();
  const msgId = opts?.msgId || newId(chatId);
  return {
    id: msgId,
    id_wpp: msgId,
    msgId: msgId,
    channelType: channelType,
    o: chatId,
    to: chatId,
    chatId: chatId,
    fromMe: true,
    quotedMsgId: replyData ? replyData?.msg.msgId : null,
    quotedMsg: replyData ? replyData?.msg : null,
    reply: replyData,
    timestamp: parseInt((+new Date() / 1000) as unknown as string),
    userIdSend: (data as any).user.id,
    profilePicUrl: (data as any).user.profilePicUrl,
    name: (data as any).user.name,
    surname: (data as any).user.surname,
    ack: 0,
    textUserName: getUserNameAddCaption(channelType),
  };
}

function editChatDefault(contactId: number) {
  const UserStore = () => useStore();
  UserStore().editContact(contactId, {
    botId: 0,
    departmentId: 0,
    userIdOpened: UserStore().user.id,
    timestamp: parseInt((+new Date() / 1000) as unknown as string),
    unreadCount: 0,
  });
}

function getUserNameAddCaption(
  channelType?: ChannelsType,
  lastMsg?: Message,
  userId?: number
) {
  const type = channelType || "whatsapp";
  const data = useStore();
  if (lastMsg?.userIdSend === userId) return "";
  const sendName = data.config.sendUsername;
  if (!sendName || sendName == (0 as any)) return "";

  const completeName = `${
    data.user.surname
      ? data.user.name + " " + data.user.surname
      : data.user.name
  }`;
  if (type == "whatsapp" || type == "whatsapp_cloud") {
    return `> ${sendName == "name" ? data.user.name : completeName}:\n`;
  } else if (type == "telegram_bot") {
    return `<b> ${sendName == "name" ? data.user.name : completeName}:</b>\n`;
  } else {
    return `${sendName == "name" ? data.user.name : completeName}:\n`;
  }
}
function updateMessageDefault(type: string, res: any) {
  const data = useStore();
  if (res?.data?.auth == false) {
    deleteSessionStoragesAndReload();
  }
  if (type === "chat") {
    data.editMessage(res.data.message.contactId, res.data.fakechatid.msgId, {
      id: res.data.message.id,
      msgId: res.data.message.msgId,
      body: res.data.message.body,
      messageSocket: res.data.message.messageSocket,
    });
    data.setAck(
      res.data.message.chatId,
      res.data.fakechatid.msgId,
      res.data.message.ack
    );
  }
  if (type === "ptt") {
    data.editMessage(res.data.message.contactId, res.data.fakechatid.msgId, {
      id: res.data.message.id,
      msgId: res.data.message.msgId,
      ack: `${res.data.message.ack}`,
      body: res.data.message.body,
      messageSocket: res.data.message.messageSocket,
    });
  }
  if (type == "file") {
    data.editMessage(res.data.message.contactId, res.data.fakechatid.msgId, {
      id: res.data.message.id,
      msgId: res.data.message.msgId,
      body: res.data.message.body,
      path: res.data.message.path,
      ack: res.data.message.ack,
      messageSocket: res.data.message.messageSocket,
    });
  }
  if (type == "image") {
    data.editMessage(res.data.message.contactId, res.data.fakechatid.msgId, {
      id: res.data.message.id,
      msgId: res.data.message.msgId,
      body: res.data.message.body,
      //path: res.data.message.path,
      ack: res.data.message.ack,
      messageSocket: res.data.message.messageSocket,
    });
  }
  if (type == "vcard") {
    data.editMessage(res.data.message.contactId, res.data.fakechatid.msgId, {
      id: res.data.message.id,
      msgId: res.data.message.msgId,
      ack: `${res.data.message.ack}`,
      body: res.data.message.body,
      messageSocket: res.data.message.messageSocket,
    });
  }
  if (type == "location") {
    const changes = [
      { name: "id", value: res.data.message.id },
      { name: "msgId", value: res.data.message.msgId },
      { name: "setAck", value: 1 },
    ];
    data.updateMessage(res.data.fakechatid.id, changes);
  }
  if (type == "buttons") {
    const changes = [
      { name: "id", value: res.data.message.id },
      { name: "msgId", value: res.data.message.msgId },
      { name: "setAck", value: 1 },
    ];
    data.updateMessage(res.data.fakechatid.id, changes);
  }
  if (type == "forward") {
    const changes = [
      { name: "id", value: res.data.message.msgId[0] },
      { name: "msgId", value: res.data.message.msgId[0] },
      { name: "setAck", value: 1 },
    ];
    data.updateMessage(res.data.fakedata.id, changes);
  }
}

function updateMessageError(msgId: string) {
  const data = useStore();
  const changes = [{ name: "setAck", value: -1 }];
  data.updateMessage(msgId, changes);
}
