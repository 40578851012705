import { ChannelsType } from "@/helpers/message";
import { UserState, UserStateActions, useStore } from "../stores/dataStore";
import { Contact } from "@/types/Contact";
import randomBytes from "randombytes";
import moment from "moment";

export enum ETypeMessage {
  chat_finished = "chat-finished",
  document = "document",
  image = "image",
  video = "video",
  ptt = "ptt",
  audio = "audio",
  vcard = "vcard",
  ciphertext = "ciphertext",
  TEXT = "text",
  CHAT = "chat",
  sticker = "sticker",
  location = "location",
  chat_buttons = "chat_buttons",
  template_button_reply = "template_button_reply",
  list = "list",
  poll = "poll",
  leave = "leave",
  miss = "miss",
  call_log = "call",
  gp2 = "gp2",
  reaction = "reaction",
}

export interface IMessage {
  id: number;
  masterId: number;
  channelId?: number;
  contactId: number;
  msgId: string;
  type?: string | null;
  subtype?: string | null;
  body?: string | null;
  from?: string;
  to: string | null;
  fromMe?: boolean;
  author?: string | null;
  ack?: string;
  star?: boolean;
  loc?: string | null;
  lat?: string | null;
  lng?: string | null;
  caption?: string | null;
  path?: string | null;
  mimetype?: string | null;
  filename?: string | null;
  size?: string | number | null;
  mentionedJidList?: string | any[] | null;
  isForwarded?: boolean;
  isNotification?: boolean;
  labels?: string | null;
  timestamp?: number;
  content?: string | null;
  isGroupMsg?: boolean;
  chatId?: string;
  quotedMsgId?: string | null;
  recipients?: string | null;
  userIdSend?: number | null;
  dataUserSended?: string | null;
  reactions?: string | null;
  list_sections?: string | any[] | null;
  buttons?: string | any[] | null;
  description?: string | null;
  title?: string | null;
  footer?: string | null;
  buttonText?: string | null;
  fileId?: string | null;
  isEdited?: boolean;
  contactsMessage?: string | any[] | null;
  messageSocket?: string | any[] | null;
  votes?: string | null;
  quotedMsg?: any;
  isBroadcast?: boolean;
  isScheduled?: boolean;
  dateScheduled?: Date | null;
  //vcardName: string | null;
  //vcardNumbers: string | null;
  createdAt?: Date;
  updatedAt?: Date | null;
}

// Definindo a classe que implementa a interface
export class Message implements IMessage {
  declare id: number;
  declare masterId: number;
  declare channelId: number;
  declare channelType: string;
  declare contactId: number;
  declare msgId: string;
  declare type: ETypeMessage;
  declare subtype?: string | null;
  declare body?: string | null;
  declare from: string;
  declare to: string | null;
  declare fromMe?: boolean;
  declare author?: string | null;
  declare ack: string;
  declare star?: boolean;
  declare loc?: string | null;
  declare lat?: string | null;
  declare lng?: string | null;
  declare caption?: string | null;
  declare path?: string | null;
  declare mimetype?: string | null;
  declare filename?: string | null;
  declare size?: string | null;
  declare mentionedJidList?: string | any[] | null;
  declare isForwarded?: boolean;
  declare isNotification?: boolean;
  declare labels?: string | null;
  declare timestamp: number;
  declare content?: string | null;
  declare isGroupMsg?: boolean;
  declare chatId: string;
  declare quotedMsgId?: string | null;
  declare recipients?: string | null;
  declare userIdSend?: number | null;
  declare dataUserSended?: string | null;
  declare reactions?: string | null;
  declare list_sections?: string | any[] | null;
  declare buttons?: string | any[] | null;
  declare description?: string | null;
  declare title?: string | null;
  declare footer?: string | null;
  declare buttonText?: string | null;
  declare fileId?: string | null;
  declare isEdited?: boolean;
  declare contactsMessage?: string | any[] | null;
  declare messageSocket?: string | any[] | null;
  declare votes?: string | null;
  declare isBroadcast: boolean;
  declare isScheduled: boolean;
  declare dateScheduled: Date | null;
  declare quotedMsg?: any;
  declare createdAt?: Date;
  declare updatedAt?: Date | null;

  // Construtor que recebe os dados e os atribui às propriedades da classe
  constructor(
    data: Pick<
      IMessage,
      | "author"
      | "type"
      | "subtype"
      | "fromMe"
      | "loc"
      | "lat"
      | "lng"
      | "body"
      | "from"
      | "caption"
      | "path"
      | "mimetype"
      | "fileId"
      | "filename"
      | "size"
      | "isForwarded"
      | "timestamp"
      | "content"
      | "isGroupMsg"
      | "quotedMsgId"
      | "userIdSend"
      | "list_sections"
      | "buttons"
      | "description"
      | "title"
      | "footer"
      | "buttonText"
      | "isEdited"
      | "contactsMessage"
      | "messageSocket"
      | "votes"
      | "reactions"
      | "isBroadcast"
      | "dateScheduled"
      | "updatedAt"
    > & { quotedMsg?: any },
    contact: Contact,
    openChat?: boolean
  ) {
    const fromMe = typeof data.fromMe === "undefined" ? true : data.fromMe;
    const msgId = "BAE5" + randomBytes(6).toString("hex").toUpperCase();
    this.id = msgId as any; //Math.floor(Math.random() * Date.now());
    this.masterId = contact.master_id;
    this.channelType = contact.contact_type;
    this.to = contact.id_api;
    this.chatId = contact.id_api;
    this.fromMe = fromMe;
    this.createdAt = new Date();
    this.ack = data.dateScheduled ? "9" : "0";
    this.content = data.content;
    this.isForwarded = false;
    this.isScheduled = data.dateScheduled ? true : false;
    this.dateScheduled = data.dateScheduled
      ? (moment(data!.dateScheduled).second(30).toDate() as any)
      : undefined;
    this.timestamp = data?.dateScheduled
      ? moment(data!.dateScheduled).unix()
      : moment().unix();
    this.msgId = msgId;
    this.channelId = contact.channelId;
    this.contactId = contact.id;
    this.type = data.type as any;
    this.subtype = data.subtype;
    this.body = data.body;
    this.from = data.from as any;
    this.author = data.author || null;
    this.star = false;
    this.loc = data.loc;
    this.lat = data.lat;
    this.lng = data.lng;
    this.caption = null;
    this.path = null;
    this.mimetype = data.mimetype || null;
    this.filename = data.filename || null;
    this.size = data.size?.toString() || null;
    this.mentionedJidList = null;
    this.isNotification = false;
    this.labels = null;
    this.content = data.content || null;
    this.isGroupMsg = data.isGroupMsg || false;
    this.quotedMsgId = data.quotedMsgId || null;
    this.recipients = null;
    this.userIdSend = data?.userIdSend || null;
    this.dataUserSended = null;
    this.reactions = data.reactions || null;
    this.list_sections = data.list_sections || null;
    this.buttons = data.list_sections || null;
    this.description = data.description || null;
    this.title = data.title || null;
    this.footer = data.footer || null;
    this.buttonText = data.buttonText || null;
    this.fileId = data.fileId || null;
    this.isEdited = data.isEdited || false;
    this.contactsMessage = data.contactsMessage || null;
    this.messageSocket = data.messageSocket || null;
    this.votes = data.votes || null;
    this.isBroadcast = data.isBroadcast || false;
    this.updatedAt = null;
    this.quotedMsg = data.quotedMsg || null;
    Object.assign(this, data);
    if (openChat === true) {
      this.UserStore().editContact(contact.id, {
        botId: 0,
        departmentId: 0,
        userIdOpened:
          data?.userIdSend !== null &&
          data?.userIdSend !== undefined &&
          data?.userIdSend > 0
            ? data.userIdSend
            : undefined,
        isOpen:
          data?.userIdSend !== null &&
          data?.userIdSend !== undefined &&
          data?.userIdSend > 0
            ? (1 as any)
            : (0 as any),
      });
    }
    this.UserStore().editContact(contact.id, {
      timestamp: parseInt((+new Date() / 1000) as unknown as string),
      unreadCount: 0,
    });

    this.UserStore().addMessage(contact.id, this as any);
  }

  private UserStore(): UserState & UserStateActions {
    return useStore() as any;
  }
}
