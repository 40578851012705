import randomBytes from "randombytes";

export function generateWhatsAppMsgId(to: string, deviceNumber?: string) {
  return "BAE5" + randomBytes(6).toString("hex").toUpperCase();
}

function generateWhatsAppMsgIdOldd(to: string, deviceNumber?: string) {
  /**
   * Old WhatsApp method
   * @deprecated
   */
  if (/^\d+@c.us$/.test(parseWid(to))) {
    return `true_${parseWid(to)}_${newId()}`;
  } else {
    if (typeof deviceNumber === "undefined")
      throw new Error("Please, send device number for send msg to groups");
    return `true_${parseWid(to)}_${newId()}_${parseWid(deviceNumber as any)}`;
  }
}

function parseWid(number: string): string {
  if (
    /^\d+@c.us$/.test(number) ||
    /^\d+-\d+@g.us$/.test(number) ||
    /^\d+@g.us$/.test(number)
  )
    return number;
  else if (/^\d+$/.test(number)) return number + "c.us";
  else if (/^\d+-\d+$/.test(number)) return number + "g.us";
  else throw new Error("Send the whatsapp number correctly");
}

function newId(): string {
  const t = new Uint8Array(8);
  const randomHex = self.crypto.getRandomValues(t);
  const parsed = parseHex(randomHex);

  return "3EB0" + parsed;
}

function parseHex(e: any) {
  const arr = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70];
  const t = [];
  for (let r = 0; r < e.length; r++) {
    const i = e[r];
    t.push(arr[i >> 4], arr[15 & i]);
  }
  return String.fromCharCode.apply(String, t);
}
