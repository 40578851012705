import { Message } from "@/types/Message";

export function getPreviousMessage(
  msgId: string,
  messages: Message[]
): Message | null {
  const index = messages.findIndex((msg) => msg.msgId === msgId);
  const previousMsg = messages[index - 1];
  return previousMsg || null;
}
