// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from "vue";
import { createPinia } from "pinia";
import VueLazyLoad from "vue3-lazyload";
import axios from "axios";
import { config } from "./public/config";
import { router } from "./router";
import App from "./App.vue";
import LayoutHeader from "./components/layout/Header.vue";
import LayoutSidebar from "./components/layout/Sidebar.vue";
import LayoutNavadmin from "./components/layout/Navadmin.vue";
import loader from "./components/loader.vue";

import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap.bundle.min.js";
import "./assets/css/font-awesome.min.css";
import "./assets/fontawesome/css/fontawesome.min.css";
import "./assets/fontawesome/css/all.min.css";
import "./assets/css/feathericon.min.css";
import jquery from "jquery";
import { socket } from "./service/socket";

(window as any).$ = (window as any).jquery = jquery;
(global as any).$ = (global as any).jquery = jquery;

if (window.location.pathname.includes("site-admin")) {
  require("./assets/css/select2.min.css");
  require("./assets/css/select2.min.js");
  require("./assets/css/style.css");
  require("./assets/css/jquery.maskedinput.min.js");
  require("./assets/css/bootstrap4.min.css");
  require("./assets/css/dataTables.bootstrap4.min.css");
  require("./assets/css/jquery.dataTables.min.js");
  require("./assets/css/dataTables.bootstrap4.min.js");
} else {
  require("./assets/css/app.css");
  require("./assets/css/swiper/swiper.min.css");
  require("./assets/css/swiper/swiper.min.js");
  require("./assets/css/jquery-ui.min.js");
  require("./assets/css/fancybox/jquery.fancybox.min.css");
  require("./assets/css/fancybox/jquery.fancybox.min.js");
}
/*
(function () { 
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./service-worker.js', {scope: '/'}).then(() =>
    console.log('Service Worker registered successfully.'))
    .catch(error => console.log('Service Worker registration failed:', error));
  }
})();
*/
const app = createApp(App);

app.component("layout-header", LayoutHeader);
app.component("layout-sidebar", LayoutSidebar);
app.component("layout-navadmin", LayoutNavadmin);
app.component("Loader", loader);
app.config.globalProperties.$user = [];
app.config.globalProperties.$config = [];
app.config.globalProperties.$chats = [];
app.config.globalProperties.$jwt = localStorage.getItem("jwt");

const api = axios.create({
  baseURL: config.$api_url,
  timeout: config.timeoutDuration,
  headers: { "x-access-token": app.config.globalProperties.$jwt },
});

api.defaults.headers.common["Authorization"] = app.config.globalProperties.$jwt;

app.config.globalProperties.$api = api;

const pinia = createPinia();
app.use(pinia);

app.use(VueLazyLoad, {
  // options...
});
app.use(router).mount("#app");
