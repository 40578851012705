import { Message } from "@/types/Message";
import getNextMessage from "./getNextMessage";
import { isMobile } from "../../helpers/funcs";

export function showAttributtesMessage(
  message: Message,
  messages: Message[],
  userId: number,
): boolean {
    if(message.chatId?.includes('g.us')) return true;
    if (isMobile()) return false;
    const nextMsg = getNextMessage(message.msgId, messages);
    if (!nextMsg || nextMsg === null) return true;

    if (
      nextMsg.type != "chat" &&
      nextMsg.type != "image" &&
      nextMsg.type != "audio" &&
      nextMsg.type != "ptt" &&
      nextMsg.type != "vcard"
    )
      return true;
    
    if(message.userIdSend) {
        if (nextMsg.userIdSend === userId) return false;
    } else {
        if(nextMsg.fromMe === message.fromMe) return false;
    }

    return true;
}
