import { IMessage } from "../models/message.model";

export function getTextOfMessageType(message: IMessage): {
  text: string;
  html: string;
} {
  let icon = "";
  if (!message || !message?.type) return { text: "", html: "" };

  if (message.userIdSend) {
    if ((message as any).ack == "0") icon = "update";
    if ((message as any).ack == "1") icon = "done";
    if ((message as any).ack == "2") icon = "done_all";
    if ((message as any).ack == "3") icon = "done_all";
    if ((message as any).ack == "4") icon = "volume_up";
    if ((message as any).ack == "-1") icon = "error";
    if (icon !== "") {
      icon = `<span class="material-icons">${icon}</span>`;
    }
  }
  if (message.type == "chat-finished") {
    return {
      text: `~ Conversa finalizada`,
      html: `<i>~ Conversa finalizada</i>`,
    };
  } else if (message.type == "reaction") {
    return {
      text: `${message.fromMe ? "Você reagiu com" : "Reagiu com"} ${
        message.content
      }`,
      html: `${message.fromMe ? "Você reagiu com" : "Reagiu com"} ${
        message.content
      }`,
    };
  } else if (message.type == "call" && message.subtype == "offer") {
    return {
      text: "Recebendo chamada de voz/video",
      html: '<p class="missed-call-col"></p><span class="material-icons">call</span> Recebendo chamada de voz/video</div>',
    };
  } else if (message.type == "call" && message.subtype == "reject") {
    return {
      text: "Ligação sem resposta",
      html: '<p class="missed-call-col"></p><span class="material-icons">call_missed</span> Ligação sem resposta</div>',
    };
  } else if (message.type == "gp2" && message.subtype == "leave") {
    return {
      text: "Um membro saiu do grupo",
      html: '<span class="material-icons">close</span> Um membro saiu do grupo</div>',
    };
  } else if ((message as any).ack === "-8") {
    return {
      text: "Mensagem apagada",
      html: "<i>Mensagem apagada</i>",
    };
  } else if (message.type == "ciphertext") {
    return {
      text: "Mensagem encriptada",
      html: "<i>Mensagem encriptada</i>",
    };
  } else if (message.type == "ptt") {
    return {
      text: "🎤 Mensagem de voz",
      html: icon + " 🎤 Mensagem de voz",
    };
  } else if (message.type === "audio") {
    return {
      text: "🔊 Áudio",
      html: icon + " 🔊 Áudio",
    };
  } else if (message.type === "document") {
    return {
      text: "📁 Arquivo",
      html: icon + " 📁 Arquivo",
    };
  } else if (message.type === "image") {
    return {
      text: "🖼️ Imagem",
      html: icon + " 🖼️ Imagem",
    };
  } else if (message.type === "poll") {
    return {
      text: "🚀 Enquete - " + (message.title || message.body),
      html: icon + " 🚀 Enquete - " + (message.title || message.body),
    };
  } else if (message.type === "video") {
    return {
      text: "🎥 Video",
      html: icon + "🎥 Video",
    };
  } else if (message.type === "vcard") {
    return {
      text: "📞 Contato",
      html: icon + "📞 Contato",
    };
  } else if (message.type === "sticker") {
    return {
      text: "🩹 Sticker",
      html: icon + "🩹 Sticker",
    };
  } else if (message.type === "location") {
    return {
      text: "📌 Localização",
      html: icon + "📌 Localização",
    };
  } else {
    return {
      text: message.content as string,
      html: `${icon} ${message.content}`,
    };
  }
}
