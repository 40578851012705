/* eslint-disable @typescript-eslint/no-this-alias */
import moment, { Moment } from "moment/moment";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";

interface DataEnded {
  (base64: string, file: File, duration: number): void;
}

export class Recorder {
  declare isRecording: boolean;
  declare recorder: any;
  declare getUserMediaStream: any;
  declare base64: string;
  declare file: File;
  declare time_start: Moment;
  declare time_end: Moment;
  declare wavesurfer: WaveSurfer;
  declare _triggers: any;
  declare time: number;

  constructor() {
    this._triggers = {};
    this.isRecording = false;
    this.recorder = "" as any;
    this.getUserMediaStream = "";
    this.base64 = "";
    this.time_start = moment();
    this.time_end = moment();
    this.time = 0;
  }
  public start() {
    if (this.wavesurfer) this.wavesurfer.destroy();

    this.isRecording = true;
    this.wavesurfer = WaveSurfer.create({
      container: "#wave",
      waveColor: "rgb(132,128,255)",
      progressColor: "rgb(100, 0, 100)",
      barWidth: 3,
      barGap: 2,
      barRadius: 2,
      height: 70,
      barHeight: 3,
    });

    // Initialize the Record plugin
    this.recorder = this.wavesurfer.registerPlugin(
      RecordPlugin.create({ renderRecordedAudio: false })
    );
    // Render recorded audio
    this.recorder.on("record-end", (blob: any) => {
      this.file = blob;
      const chunks = [];
      chunks.push(blob);
      const blobou = new Blob(chunks, { type: "audio/mpeg-3" });
      this.file = new File([blob], "audio.mpeg", { type: "audio/mpeg" });

      const reader = new window.FileReader();
      reader.readAsDataURL(blobou);
      const that = this;
      reader.onloadend = function () {
        const base64 = reader.result;
        (that as any).base64 = base64;
        //that.triggerHandler(that.base64, that.file);
      };
    });

    this.recorder.on("record-progress", (time: number) => {
      this.updateProgress(time);
    });

    if (this.recorder.isRecording() || this.recorder.isPaused()) {
      this.isRecording = false;
      this.recorder.stopRecording();
      return;
    }
    this.recorder.startRecording();
  }

  destroy() {
    if (this.recorder.isRecording() || this.recorder.isPaused()) {
      this.recorder.destroy();
      this.wavesurfer.destroy();
      const pgrs = document.getElementById("progress-recorder");
      pgrs!.textContent = "";
      this.isRecording = false;
      return;
    }
  }
  stop() {
    if (this.recorder.isRecording() || this.recorder.isPaused()) {
      this.recorder.destroy();
      this.wavesurfer.destroy();
      this.recorder.stopRecording();
      const pgrs = document.getElementById("progress-recorder");
      pgrs!.textContent = "";
      this.isRecording = false;
      setTimeout(() => {
        this.triggerHandler(this.base64, this.file, this.time);
      }, 200);
      return;
    }
  }

  public pause() {
    if (this.recorder.isPaused()) {
      this.recorder.resumeRecording();
      return;
    }

    this.recorder.pauseRecording();
  }

  private updateProgress(time: number) {
    this.time = (time % 60000) / 1000;
    const progress = document.querySelector("#progress-recorder");
    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
    if (progress) progress!.textContent = formattedTime;
  }
  public on(event: "record-ended", callback: DataEnded) {
    if (!this._triggers[event]) this._triggers[event] = [];
    this._triggers[event].push(callback);
  }

  private triggerHandler(...args: any) {
    if (this._triggers["record-ended"]) {
      for (const i in this._triggers["record-ended"])
        this._triggers["record-ended"][i](...args);
    }
  }
}
