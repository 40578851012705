/* eslint-disable no-useless-escape */
export function formatHtmlTextChat(text: string) {
  if (!text) {
    return "";
  }
  let html = text;
  if (/(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g.test(text)) {
    html = text.replace(
      /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g,
      ' <a target="_blank" href="$2">$2</a>'
    );
  } else {
    html = text.replace(
      /(www\..+?\..+?)(\s|&gt;|$)/g,
      ' <a target="_blank" href="http://$1">$1</a>'
    );
  }

  html = html
    .replace(/\B\*(.*?)\*/gm, "<strong>$1</strong>")
    .replace(/\B\~(.*?)\~/gm, "<strike>$1</strike>")
    //.replace(/\b\_(.*?)\_/gm, "<i>$1</i>")
    .replace(/\n/g, "<br/>");
  return html;
}
