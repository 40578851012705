/* eslint-disable no-async-promise-executor */
import { ICompany } from "@/types/Company";
import { defineStore } from "pinia";
import { api } from "../service/api";

export type RootState = {
  items: ICompany[];
};

export const Companies = defineStore({
  id: "companiesStore",
  state: () =>
    ({
      items: [],
    } as RootState),
  actions: {
    create(payload: Partial<ICompany>): Promise<ICompany> {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await api.post(`/company/new`, {
            ...payload,
          });
          resolve(data);
        } catch (error: any) {
          reject(error);
        }
      });
    },
    destroy(id: number): Promise<boolean> {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await api.delete(`/company/${id}`);
          resolve(data);
        } catch (error: any) {
          reject(error);
        }
      });
    },
    update(id: number, payload: Partial<ICompany>): Promise<ICompany> {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await api.put(`/company/${id}`, payload);
          resolve(data);
        } catch (error: any) {
          reject(error);
        }
      });
    },
    findAll(): Promise<ICompany[]> {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await api.get(`/companies/all`);
          this.items = data;
          resolve(this.items);
        } catch (error: any) {
          reject(error);
        }
      });
    },
    find(text: string): Promise<ICompany[]> {
      if (!text) return [] as any;
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await api.get(`/companies/find`, {
            params: {
              query: text,
            },
          });
          resolve(data);
          for (const item of data) {
            this.addOnStore(item);
          }
        } catch (error: any) {
          reject(error);
        }
      });
    },
    findById(id: number): Promise<ICompany> {
      return new Promise(async (resolve, reject) => {
        try {
          const exist = this.getById(id);
          if (!exist) {
            const { data } = await api.get(`/company/${id}`);
            this.addOnStore(data);
            resolve(data);
          } else {
            resolve(exist);
          }
        } catch (error: any) {
          reject(error);
        }
      });
    },
    addOnStore(company: ICompany): void {
      const get = this.getById(company?.id);

      if (get?.id) {
        const i = this.items?.indexOf(get);
        this.items[i] = company;
      } else {
        this.items.push(company);
      }
      this.sortByAz();
    },
    sortByAz() {
      this.items = this.items.sort((a, b) =>
        a.fantasyName.localeCompare(b.fantasyName)
      );
    },

    /**
     * Stores functions
     */
    getById(id: number): ICompany | undefined {
      return this.items.find((item: ICompany) => item.id === id);
    },
  },
});
