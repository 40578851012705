let config: {
  $api_url: string;
  $base_url: string;
  timeoutDuration: number;
};

if (process.env.NODE_ENV === "production") {
  config = {
    $api_url: "https://api.titanchat.com.br",
    $base_url: "https://app.titanchat.com.br",
    timeoutDuration: 30000,
  };
} else {
  config = {
    $api_url: "http://localhost:28896",
    $base_url: "http://localhost:8080",
    timeoutDuration: 30000,
  };
}

export { config };
