import { db } from "@/dbs/db";
import { Contact } from "@/types/Contact";
import { defineStore } from "pinia";

export type RootState = {
  items: Contact[];
};

export const ChatCollection = defineStore({
  id: "chats",
  state: () =>
    ({
      items: [] as Contact[],
    } as RootState),

  actions: {
    get(id: number): Contact | undefined {
      return this.items?.find((contact: Contact) => contact?.id == id);
    },
    push(contact: Contact | Contact[]) {
      if (!contact) return;
      // Verifica se o chat existe e atualiza ele pelo index, caso não só da um push na array
      const addChatToItem = (chat: Contact) => {
        const exist = this.items.find((finded) => finded.id === chat.id);
        if (typeof exist !== "undefined") {
          const index = this.items.findIndex(
            (finded) => finded.id === exist.id
          );
          this.items[index] = chat;
        } else {
          this.items.push(chat);
        }
      };

      if (Array.isArray(contact)) {
        contact.map((chat) => {
          addChatToItem(chat);
        });
      } else {
        addChatToItem(contact);
      }
    },

    update(id: number, payload: Partial<Contact>) {
      if (!id || !payload) return;

      const index = this.findIndexById(id);

      if (index !== -1) {
        //this.contacts[index] = generateFakeData();
      }
    },

    destroy(id: number) {
      const index = this.findIndexById(id);
      if (index === -1) return;
      this.items.splice(index, 1);
    },

    findIndexById(id: number) {
      return this.items.findIndex((contact) => contact.id === id);
    },
  },
});
