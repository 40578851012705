import moment from "moment";

export function formatDateForChat(timestamp: number) {
    const timestamp2 = moment.unix(timestamp)

    var REFERENCE = moment();
    var TODAY = REFERENCE.clone().startOf('day');
    var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    var A_WEEK_OLD = REFERENCE.clone().subtract(2, 'days').startOf('day');
    var A_YEAR_OLD = REFERENCE.clone().subtract(364, 'days').startOf('day');

    if(timestamp2.isSame(TODAY, 'd')){
        return moment.unix(timestamp).format("HH:mm")
    }
    if(timestamp2.isSame(YESTERDAY, 'd')){
        return 'ontem às '+moment.unix(timestamp).format("HH:mm")
    }
    if(timestamp2.isAfter(A_WEEK_OLD)){
        return moment.unix(timestamp).format("DD/MM HH:mm")
    }
    if(timestamp2.isAfter(A_YEAR_OLD)){
        return moment.unix(timestamp).format("DD/MM/YYYY HH:mm")
    }
}