/* eslint-disable prefer-rest-params */
import { defineStore } from "pinia";
import { api } from "../service/api";
import { socket } from "../service/socket";
import { v4 as uuidv4 } from "uuid";
import {
  isDarkMode,
  isMobile,
  deleteSessionStoragesAndReload,
} from "../helpers/funcs";
import {
  playSoundMessageGroup as playGroup,
  playSoundMessageCtt as playCtt,
} from "../helpers/playSounds";
import { notifyWide } from "../helpers/notifications.js";
import { errorSwal } from "../helpers/alertSwal.js";
import { config } from "../public/config";
import { Channel } from "../types/Channel";
import { Message } from "../types/Message";
import { Contact } from "../types/Contact";
import { User } from "../types/User";
import moment from "moment";
import { db } from "../dbs/db";
import { getChats } from "../helpers/chats/getChats";
import { ChatCollection } from "./chatsStore";

export interface UserState {
  readyConnection: boolean;
  userisMobile: boolean;
  darkMode: boolean;
  audioBitRate: number;
  user: User;
  users: User[];
  config: User;
  contacts: Contact[];
  chats: Contact[];
  labels: any[];
  departments: any[];
  fastmsgs: any[];
  channels: Channel[];
  chats_history: any[];
  announces: any[];
  chatIdTransfer: Contact | null;
  modalForward: boolean;
  forward_chatsId: string[];
  forward_contactIds: number[];
  forward_msgIds: string[];
  forward_openChat: boolean;
  forward_chatType: string;
  forward_messages: any[];
  newContact: { name: string; phone: string; contact_type: string }[];
  apiURL: string;
  token: string | null;
  isLoading: boolean;
  openedChat: Contact | null;

  isSyncing: boolean;
  percentSyncing: number;
  countEventsSync: number;
  processedEventsSync: number;
}
export interface UserStateActions {
  clearActiveChat(): void;
  getActiveChat(): Contact | null;
  addContact(contact: Contact): void;
  editContact(id: number, contactEdited: Partial<Contact>): Promise<Contact>;
  getContact(id_api: string): Contact;
  getContactById(id: number): Promise<Contact>;
  getChat(id: number): Contact | undefined;
  getChatByIdAPI(id: number): Contact | undefined;
  addMessage(contactId: number, message: Partial<Message>): void;
  editMessage(
    contactId: number,
    msgId: string,
    messageEdited: Partial<Message>
  ): Message;
  findMessage(contactId: number, msgId: string, id: number): Message;
  processAndAddMessage(
    contact: Contact,
    message: Message,
    userId?: number,
    type?: "forward"
  ): Contact;
  playNewMessageSound(contact: Contact): void;
  notifyDesktop(contact: Contact, message: Message): void;
  assignChatToMe(contacto: Contact): void;
  getChannel(type: string): Channel;
  destroyMessage(id: number): void;

  getUserById(id: number): any;

  syncChats(): Promise<any>;
  getFullData(): Promise<void>;
  setAck(chatId: string, msgId: string, ack: any): void;
}

export const useStore = defineStore("data", {
  state: (): UserState => ({
    readyConnection: true,
    userisMobile: false,
    darkMode: false,
    audioBitRate: 1.0,
    user: {} as User,
    users: [] as User[],
    config: {} as User,
    contacts: [] as Contact[],
    labels: [],
    departments: [],
    fastmsgs: [],
    channels: [] as Channel[],
    chats: [] as Contact[],
    chats_history: [],
    announces: [],
    chatIdTransfer: null,
    modalForward: false,
    forward_chatsId: [],
    forward_contactIds: [],
    forward_msgIds: [],
    forward_openChat: true,
    forward_chatType: "",
    forward_messages: [],
    token: null,

    newContact: [{ name: "", phone: "", contact_type: "" }],
    apiURL: config.$api_url + "/",
    isLoading: true,
    openedChat: null,
    isSyncing: false,
    percentSyncing: 0,
    countEventsSync: 0,
    processedEventsSync: 0,
  }),
  actions: {
    async getFullData() {
      try {
        await this.syncChats();
        this.isLoading = false;
        await this.getData();
      } catch (error) {
        this.getFullData();
      }
    },
    async getData(token?: string): Promise<void> {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (token) {
          api.interceptors.request.use(function (config): any {
            config.headers.Authorization = token;
            config.headers["x-access-token"] = token;
            return config;
          });
          socket.auth = {
            token: token,
          };
          socket.connect();
        }
        await api
          .get("/account-data")
          .then(async (res) => {
            const { data } = res as any;
            this.config = data;
            this.departments = data.departments;
            this.labels = data.labels;
            this.channels = data.channels;
            this.fastmsgs = data.fastmsgs;
            this.users = data.users;
            (this.users as any).unshift(data);
            await db.config.clear();
            await db.departments.clear();
            await db.labels.clear();
            await db.channels.clear();
            await db.fastmsgs.clear();
            await db.users.clear();
            await db.config.add(data);
            await db.departments.bulkAdd(data.departments);
            await db.labels.bulkAdd(data.labels);
            await db.fastmsgs.bulkAdd(data.fastmsgs);
            await db.channels.bulkAdd(data.channels);
            await db.users.bulkAdd(data.users);
            resolve();
          })
          .catch((err) => {
            reject();
            deleteSessionStoragesAndReload(err);
          });
      });
    },
    async getUserData() {
      if (typeof this.user.id === "undefined") {
        try {
          const { data } = await api.get("/user-data");
          this.user = data.user;
          this.channels = data.channels;

          await api.get("/announcements/all").then((result: any) => {
            if (result.data.length > 0) {
              this.announces = result.data;
            }
          });
        } catch (error) {
          deleteSessionStoragesAndReload();
        }

        this.userisMobile = isMobile();
        await isDarkMode().then((value) => {
          this.darkMode = value;
        });
      }
    },
    async syncChats() {
      try {
        this.syncIDB();
        const { data } = await api.get("/sync-chats");
        const fixedData = data.map((chat: Contact) => {
          chat.isOpen = chat.isOpen ? 1 : (0 as any);
          return chat;
        });
        const contacts = await db.contacts.toArray();
        if (contacts?.length === 0) {
          db.contacts
            .bulkAdd(fixedData)
            .then((result) => {
              console.log(result);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          for (const chat of fixedData) {
            const contato = await db.contacts.get(chat.id);
            if (contato) {
              // Aqui verificamos se a última mensagem do chat no frontend é diferente do server,
              // e se for, nós adicionamos as mensagens pendentes
              for (const msg of chat.Messages) {
                const message = contato.Messages.find(
                  (message: Message) => msg.id === message.id
                );
                if (!message) {
                  contato.Messages.push(msg);
                }
              }
              db.table("contacts").update(contato.id, {
                isOpen: chat.isOpen,
                userIdOpened: chat.userIdOpened,
                botId: chat.botId,
                departmentId: chat.departmentId,
                labels: chat.labels,
                Messages: contato.Messages,
              });
            } else {
              if (chat.id && chat.id_api) {
                db.table("contacts").add(chat);
              }
            }
          }
        }
      } catch (error) {
        deleteSessionStoragesAndReload(error);
      }
    },
    async getMasterData() {
      try {
        if (typeof this.config === "undefined") {
          const { data } = await (api as any).get("/account/master/config", {
            socket: socket.id,
          });
          if (data.auth === false) deleteSessionStoragesAndReload();
          const setConfig = async () => {
            this.config = data.config;
            this.departments = data.config.departments;
            this.labels = data.config.labels;
            this.channels = data.config.channels;
            this.fastmsgs = data.config.fastmsgs;
          };
          await setConfig();
        }
      } catch (error) {
        deleteSessionStoragesAndReload();
        console.log(
          "erro ao recuperar as configurações da conta principal " + error
        );
      }
    },
    async getAllContacts() {
      try {
        const { data } = await api.get(`/contacts/count`);
        const contacts = await db.contacts.toArray();
        if (parseInt(data) === contacts.length) return;
        if (parseInt(data) - contacts.length > 20) {
          const { data } = await api.get(`/contacts/all-contacts`);
          await this.updateOrPushContactIDB(data);
        } else {
          const orderedById = contacts.sort((a, b) => a.id - b.id);
          const lastChat = orderedById[orderedById.length - 1];
          const { data } = await api.get(`/contacts/all-contacts`, {
            params: {
              id: lastChat.id,
              direction: "after",
            },
          });
          await this.updateOrPushContactIDB(data);
        }
      } catch (error) {
        console.log(`getAllContacts() error`, error);
      }
    },

    async getAllLabels(force = false) {
      if (this.labels.length == 0 || force) {
        this.labels = await api
          .get("/labels")
          .then((res) => {
            return res.data.labels;
          })
          .catch((err) => {
            console.log("erro ao recuperar Tags" + err);
          });
      }
    },

    async getAllChannels(force = false): Promise<void> {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (this.channels.length == 0 || force) {
            const { data } = await api.get("/channels/all");
            this.channels = data.channels;
            resolve();
          } else {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    async getAllUsers(force = false) {
      if (this.users.length == 0 || force) {
        this.users = await api
          .get("/users/all")
          .then((res) => {
            return res.data.users;
          })
          .catch((err) => {
            console.log("erro ao recuperar usuários" + err);
          });
      }
    },
    async getAllDepartments() {
      api
        .get("/departments")
        .then((res) => {
          this.departments = res.data.departments;
          for (let i = 0; i < this.departments.length; i++) {
            const element = this.departments[i];
            element.users = JSON.parse(element.users);
          }
        })
        .catch((err) => {
          errorSwal("Erro ao buscar departamentos " + err);
        });
    },

    async addMessage(
      contactId: number,
      message: Partial<Message>,
      contact?: Contact
    ) {
      if (!contactId || !message) return;
      const msg = await this.findMessage(
        contactId,
        message.msgId as string,
        message.id as number
      );
      let chat = null;
      if (contact) {
        // Verificamos se não existe no store, e se não existir adicionamos o contato que chegou do server para evitar chamar atoa.
        const ctt = await db.contacts.get(contactId);
        if (!ctt) await db.contacts.add(contact);
      }
      chat = await this.getContactById(contactId);
      if (!msg) {
        if (!chat?.Messages) {
          chat.Messages = [message as any];
          ChatCollection().push(chat);
          await db.contacts.update(chat.id, chat as any);
        } else {
          chat.Messages.push(message as any);
          ChatCollection().push(chat);
          await db.contacts.update(chat.id, {
            Messages: JSON.parse(JSON.stringify(chat.Messages)),
          });
        }
      }
    },
    async findMessage(
      contactId: number,
      msgId: string,
      id: number,
      log?: boolean
    ): Promise<Message> {
      let message = null;
      const chat = await this.getContactById(contactId);

      message = chat?.Messages?.find(
        (message: Message) => message.msgId === msgId
      );

      return message as any;
    },
    getMessage(messageId: string): Message {
      this.contacts?.map((chat: Contact) => {
        if (chat?.Messages) {
          return chat.Messages.find((msg: Message) => msg.msgId == messageId);
        }
      }) as any;
      return false as any;
    },
    async getMessageByMsgIdFromServer(messageId: string): Promise<Message> {
      try {
        const { data } = await api.get(`/message/replyed/${messageId}`);
        return data;
      } catch (error) {
        throw new Error("Not found");
      }
    },
    async getMessageByMsgId(
      msgId: string,
      contactId?: number
    ): Promise<Message | undefined> {
      if (contactId) {
        const chat = await this.getContactById(contactId);
        if (chat?.Messages) {
          return chat.Messages.find((msg: Message) => msg.msgId == msgId);
        }
      } else {
        this.contacts?.map((chat: Contact) => {
          if (chat?.Messages) {
            return chat.Messages.find((msg: Message) => msg.msgId == msgId);
          }
        }) as any;
      }
    },
    updateMessage(msgId: string, changes: any) {
      this.contacts?.map((ctt: Contact) => {
        if (ctt?.Messages !== undefined) {
          ctt?.Messages.map((msg: Message) => {
            if (msg.msgId == msgId) {
              for (let index = 0; index < changes.length; index++) {
                const element = changes[index];
                if (element.name == "id") msg.id = element.value;
                if (element.name == "setAck") msg.ack = element.value;
                if (element.name == "addReaction")
                  msg.reactions = element.value;
                if (element.name == "msgId") msg.msgId = element.value;
                if (element.name == "body") msg.body = element.value;
                if (element.name == "content") msg.content = element.value;
                if (element.name == "type") msg.type = element.value;
                if (element.name == "timestamp") msg.timestamp = element.value;
              }
            }
          });
        }
      });
    },
    destroyMessage(id: number) {
      if (!id) return;
      this.contacts?.map((ctt: Contact) => {
        if (ctt.Messages !== undefined) {
          const msg = ctt.Messages.find(
            (msg) => msg.id === parseInt(id.toString())
          );
          if (msg) {
            ctt.Messages = ctt.Messages.filter(
              (msg) => msg.id != parseInt(id.toString())
            );
          }
        }
      });
    },
    async addReactionMsg(
      msgId: string,
      reaction: any,
      contact: Contact,
      id: string
    ) {
      //const message = this.getMessage(msgId);
      const msg = await this.getMessageByMsgId(msgId, contact.id);
      msg!.reactions = reaction;
      const contato = await this.getContactById(contact.id);
      contato?.Messages?.push({
        id: contact?.Messages[contact.Messages.length - 1].id + 1 || 9999,
        masterId: contact.master_id,
        channelId: contact.channelId,
        contactId: contact.id,
        msgId: id,
        type: "reaction",
        subtype: null,
        body: reaction,
        content: reaction,
        from: null,
        to: null,
        fromMe: true,
        ack: "1",
        isNotification: null,
        labels: null,
        quotedMsg: msg,
        timestamp: Math.floor(moment().valueOf() / 1000),
        chatId: contact.id_api,
        quotedMsgId: msgId,
        createdAt: moment().toDate(),
        updatedAt: null,
      } as any);
    },
    async getMessages(type: string, chatId: number): Promise<void> {
      return new Promise((resolve, reject) => {
        if (type == "whatsapp") {
          api
            .get("/messages/whatsapp/" + chatId + "/0")
            .then((res) => {
              if (res.data.auth == false) {
                deleteSessionStoragesAndReload();
              }
              if (res.data.message.length == 0) {
                this.addMessage(chatId, {
                  id: uuidv4() as unknown as number,
                  msgId: uuidv4(),
                  type: "nomessage",
                  content: "Não há mensagens nesse chat",
                  userIdSend: -1,
                  ack: `-1`,
                });
                resolve();
              } else {
                resolve();
              }
            })
            .catch((err) => {
              reject(err);
              errorSwal("Erro ao recuperar as mensagens " + chatId);
            });
        }
      });
    },

    // Actions para editar/geter chats
    addChat(chat: Contact) {
      return this.addContact(chat);
    },
    async addContact(contact: Contact) {
      const existChat = await this.getContactById(contact.id);

      if (!existChat) {
        if (typeof contact?.Messages === "undefined") {
          (contact as any).Messages = [];
        }
        await db.contacts.add(contact);
        this.contacts?.push(contact);
      } else {
        this.editContact(contact.id, contact);
      }
    },
    /**
     * Delete contact from account
     * @param {chatId} chatId Id Principal do chat
     * @returns nothing
     */
    async delContact(chatId: string): Promise<void> {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          this.contacts = this.contacts?.filter(
            (ctt: Contact) => ctt.id_api != chatId
          );
          const contato = await db
            .table("contacts")
            .where("id_api")
            .equals(chatId)
            .toArray();
          if ((contato as any)[0]?.id) {
            await db.contacts.delete((contato as any)[0].id);
            await api.delete(`/contact/${chatId}`);
          }
          resolve();
        } catch (error: any) {
          reject(error);
          throw new Error(error);
        }
      });
    },
    async editContact(
      id: number,
      contactEdited: Partial<Contact>
    ): Promise<Contact> {
      const contact = await this.getContactById(id);
      db.table("contacts").update(
        id,
        JSON.parse(JSON.stringify(contactEdited))
      );
      if (this.openedChat?.id === id) {
        for (const item of Object.keys(contactEdited)) {
          if (item === "Messages") continue;
          if ((contactEdited as any)[item] == (this.openedChat as any)?.[item])
            continue;
          (this.openedChat as any)[item] = (contactEdited as any)[item];
        }
      }
      for (const item of Object.keys(contactEdited)) {
        if (item === "Messages") continue;
        if ((contactEdited as any)[item] == (contact as any)?.[item]) continue;
        (contact as any)[item] = (contactEdited as any)[item];
      }
      return contact;
    },
    async editMessage(
      contactId: number,
      msgId: string,
      messageEdited: Partial<Message>
    ): Promise<Message> {
      const contact = await this.getContactById(contactId);
      let returnMessage: any = null;
      contact?.Messages.forEach((message: Message) => {
        if (message.msgId === msgId) {
          let processedMsg = 0;
          for (const item of Object.keys(messageEdited)) {
            ++processedMsg;
            (message as any)[item] = (messageEdited as any)[item];
            if (processedMsg === contact.Messages.length) {
              db.contacts.update(
                contact.id,
                JSON.parse(JSON.stringify(contact)) as any
              );
              returnMessage = message;
            }
          }
          return message;
        }
      }) as any;
      this.editContact(contact.id, contact);

      //Editando no chat Collection
      const chat = ChatCollection().get(contact.id);
      const index = chat?.Messages?.findIndex(
        (message: Message) => message.msgId === msgId
      );
      if (index && index >= 0) {
        const fixedEdit = chat!.Messages[index];
        for (const item of Object.keys(messageEdited)) {
          (chat!.Messages as any)[index][item] = (messageEdited as any)[item];
        }
        /*chat!.Messages[index].ack = "2";*/
        if (this?.openedChat?.id === contact.id) {
          this.openedChat!.Messages[index] = fixedEdit;
        }
      }
      return returnMessage;
    },
    async setAck(
      chatId: string,
      msgId: string,
      ack: number | string
    ): Promise<void> {
      const contact = (
        await db.contacts.where("id_api").equals(chatId).toArray()
      )[0];
      if (contact) {
        contact?.Messages.forEach((message: Message) => {
          if (message.msgId === msgId) {
            if (
              ack?.toString() != "-8" &&
              parseInt(ack.toString()) < parseInt(message?.ack?.toString())
            )
              return;
            this.editMessage(contact.id, msgId, {
              ack: `${ack}`,
            });
            return message;
          }
        });
      }
    },
    findChat(chatId: string) {
      return this.contacts?.find((chat: Contact) => chat.id_api === chatId);
    },

    /**
     * Get the contact to edit by id row of db
     * @param {*} id ID of primary row of table
     * @returns Contact
     */
    async getContactById(id: number): Promise<Contact> {
      if (!id) throw new Error("Undefined ID on getContactById");
      const chat = ChatCollection().get(id);
      if (chat) return chat;
      if (id === this.openedChat?.id) return this.openedChat;
      const contact = await db.table("contacts").get(id);
      if (!contact) {
        const { data } = await api.get(`/contact/${id}`);
        if (!data) return {} as any;
        const contact = {
          ...data,
          ...(!data?.Messages ? { Messages: [] } : undefined),
        };
        await db.contacts.add(contact);
        return (await db.contacts.get(data.id)) as Contact;
      } else {
        return contact;
      }
    },

    getContact(id_api: string) {
      return this.contacts?.find(
        (contact: Contact) => contact?.id_api == id_api
      );
    },

    getChatByIdAPI(id_api: string): Contact | undefined {
      return this.chats?.find((contact: Contact) => contact?.id_api == id_api);
    },
    async assignChatToMe(contacto: Contact): Promise<void> {
      const contact = await this.editContact(contacto.id, {
        isOpen: 1 as any,
        botId: 0,
        departmentId: 0,
        userIdOpened: this.user.id,
        isTransfered: 0,
        transferedById: 0,
        timestamp: parseInt((+new Date() / 1000) as any),
      } as any);
      if (!contact.Messages) {
        contact.Messages = contacto.Messages;
      }
      ChatCollection().push(contact);
      await api
        .post("contacts/open/", { id_api: contacto.id_api })
        .catch((err) => console.log(err));
    },

    async processAndAddMessage(
      contact: Contact,
      message: Message,
      userId?: number,
      type?: "forward"
    ): Promise<Contact> {
      if (!contact?.id) return null as any;
      if (message.quotedMsgId) {
        const msgSearch = await this.findMessage(
          contact.id,
          message.quotedMsgId as any,
          0
        );
        (message as any).quotedMsg =
          msgSearch ||
          (await this.getMessageByMsgIdFromServer(message.quotedMsgId));
      }
      const msg = await this.findMessage(
        contact?.id,
        message.msgId as string,
        message.id as number
      );
      if (!msg) {
        await this.addMessage(contact.id, message, contact);
        if (!(userId !== this.user.id && type === "forward")) {
          await this.editContact(contact.id, contact);
        }
        if (
          typeof userId === "undefined" &&
          message.userIdSend !== -2 &&
          message.userIdSend !== -1 &&
          contact.userIdOpened === this.user.id
        ) {
          this.playNewMessageSound(contact, message);
          this.notifyDesktop(contact, message);
        }
      }
      return await this.getContactById(contact.id);
    },

    playNewMessageSound(contact: Contact, message: Message): void {
      if (!this.user.notifySound || this.user.notifyDesktop || message.fromMe)
        return;
      if (contact.id_api.includes("@g.us")) {
        playGroup();
      } else {
        if (
          contact.userIdOpened === 0 ||
          contact.userIdOpened == this.user.id
        ) {
          playCtt();
        }
      }
    },
    notifyDesktop(contact: Contact, message: Message): void {
      if (!this.user.notifyDesktop || message.fromMe) return;
      notifyWide(
        contact.name,
        message.body,
        this.getContactProfilePic(contact.profilePicUrl || "")
      );
    },
    formatParticipantsGroup() {
      /*
            var chats = this.contacts.filter((chat) => chat.sender.id_api.includes('@c.us') || chat.sender.contact_type === "telegram_bot"
            || chat.sender.contact_type === "messenger" || chat.sender.contact_type === "instagram" || chat.sender.contact_type === "twitter");
            var groups = this.contacts.filter((chat) => chat.sender.id_api.includes('@g.us'));
            for (let i = 0; i < groups.length; i++) {
                const el = groups[i];
                var participants = JSON.parse(el.sender.participants)
                participants.map((ptpt) => {
                    ptpt.name = ptpt.id.user
                    ptpt.profilePicUrl = ''
                    ptpt.isOnline = false
                })
                participants.map((ptpt) => {
                    this.contacts.map(ctt => {
                        if(ctt.sender.id_api == ptpt.id._serialized){
                            ptpt.name = ctt.sender.name
                            ptpt.isMyContact = true;
                            ptpt.profilePicUrl = ctt.sender.profilePicUrl;
                        }
                    })
                })
                participants.sort((a,b) => b.name.localeCompare(a.name))
                el.sender.participants = JSON.stringify(participants)
                
            }
            this.contacts = groups.concat(chats)*/
    },

    /**
     * Users actions
     */
    getUserById(id: number) {
      return this.users.find((user: any) => user.id === id);
    },
    getContactProfilePic(profilePic: string) {
      if (profilePic) {
        return `${config.$api_url}/${profilePic}`;
      } else {
        return null;
      }
    },
    getMasterCustomDatas(): any {
      if (Array.isArray(this.config?.customdata)) {
        return this.config.customdata;
      } else if (this.config?.customdata) {
        return JSON.parse(this.config?.customdata) || [];
      } else {
        return [];
      }
    },
    async getChannel(type: string): Promise<Channel | undefined> {
      await this.getAllChannels();
      return this.channels.find((channel: Channel) => {
        if (channel.type === type) return channel;
      });
    },
    async addContactsOnStore(contacts: Contact[]): Promise<void> {
      if (!Array.isArray(contacts)) contacts = [contacts] as Contact[];
      return new Promise((resolve) => {
        for (const contact of contacts) {
          const contactInStore = this.contacts?.find(
            (ctt) => ctt?.id === contact?.id
          );
          if (!contactInStore) {
            if (!contact?.Messages) {
              contact.Messages = [];
            }
            db.table("contacts").add(contact);
            this.contacts?.push(contact);
          }
        }
        resolve();
      });
    },
    clearActiveChat(): void {
      this.openedChat = null;
    },
    getActiveChat(): Contact | null {
      return this.openedChat;
    },

    async syncIDB(): Promise<void> {
      try {
        this.isSyncing = true;
        this.percentSyncing = 0;
        this.countEventsSync = 0;
        this.processedEventsSync = 0;
        const chats = await getChats();
        this.countEventsSync = chats.length;
        const myChats = chats.filter(
          (chat) => chat.userIdOpened === this.user.id
        );
        const otherUserChats = chats.filter(
          (chat) => chat.userIdOpened !== this.user.id
        );
        await this.getAndUpdateMessagesFromContactsAndSyncIDB(myChats);
        await this.getAndUpdateMessagesFromContactsAndSyncIDB(otherUserChats);

        this.isSyncing = false;
      } catch (error) {
        throw new Error(`Erro na sincronização inicial, ${error}`);
      }
    },
    async getAndUpdateMessagesFromContactsAndSyncIDB(
      chats: Contact[]
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          let processedCount = 0;
          chats.map(async (chat) => {
            let contact = ChatCollection().get(chat.id);
            if (!contact) contact = (await db.contacts.get(chat.id)) || chat;
            const lastMessage = contact?.Messages?.filter(
              (message) => message.ack != "0"
            )?.pop();
            const Messages = await this.queryMessages(chat.id_api, chat.id, {
              type: "whatsapp",
              lastMsgId: lastMessage?.id || undefined,
              direction: "after",
            });
            const msgs = contact?.Messages || [];
            Messages.map((msg) => msgs.push(msg));
            const msgsOrdered = msgs.sort((a, b) => a.id - b.id);
            const chatWithMessages = Object.assign({
              ...chat,
              Messages: msgsOrdered,
            });
            ChatCollection().push(chatWithMessages);
            try {
              await db.contacts.add(
                JSON.parse(JSON.stringify(chatWithMessages))
              );
            } catch (error) {
              await db.contacts.update(
                chat.id,
                JSON.parse(JSON.stringify(chatWithMessages))
              );
            }
            this.processedEventsSync++;
            this.updatePercentSyncIDB();
            ++processedCount;
            if (processedCount === chats.length) resolve();
          });
        } catch (error) {
          reject(error);
          throw new Error(`Erro ao syncronizar IDB database ${error}`);
        }
      });
    },
    updatePercentSyncIDB(): void {
      this.percentSyncing =
        (this.processedEventsSync / this.countEventsSync) * 100;
    },
    async getDataFromIDB(): Promise<void> {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        try {
          this.config = ((await db.config.toArray()) as any)?.[0];
          this.departments = await db.departments.toArray();
          this.labels = await db.labels.toArray();
          this.channels = await db.channels.toArray();
          this.fastmsgs = await db.fastmsgs.toArray();
          this.users = await db.users.toArray();
          const chats = await db.contacts.where("isOpen").equals(1).toArray();
          ChatCollection().items = chats;

          this.isLoading = false;
          resolve();
        } catch (error) {
          console.log("getDataFromIDB() erro: ", error);
          resolve(await this.getDataFromIDB());
        }
      });
    },
    async queryMessages(
      chatId: string,
      contactId: number,
      opts?: {
        type?: string;
        lastMsgId?: number;
        offset?: number;
        quantity?: number;
        direction?: "after" | "before";
      }
    ): Promise<Message[]> {
      try {
        const { data } = await api.get(
          `/messages/${opts?.type || "whatsapp"}/${chatId}/${contactId}/${
            opts?.lastMsgId
          }/${opts?.offset || 0}/${opts?.offset || 20}`,
          {
            params: {
              direction: opts?.direction,
            },
          }
        );
        if (data.auth == false) deleteSessionStoragesAndReload();
        return data.message as Message[];
      } catch (error) {
        errorSwal("Erro ao recuperar as mensagens " + chatId);
        throw new Error("Erro ao recuperar as mensagens " + chatId);
      }
    },
    async updateOrPushContactIDB(contacts: Contact | Contact[]): Promise<void> {
      try {
        const addContact = async (contact: Contact) => {
          const IDBContact = await db.contacts.get(contact.id);
          if (IDBContact === contact) return;
          if (IDBContact) {
            contact.Messages = IDBContact.Messages;
            const removedID = JSON.parse(JSON.stringify(contact));
            await db.contacts.update(contact.id, removedID as any);
          } else {
            contact.Messages = [];
            await db.contacts.add(contact as any);
          }
        };
        if (Array.isArray(contacts)) {
          contacts.forEach(async (contact) => {
            (contact as any).isOpen = contact.isOpen === true ? 1 : 0;
            await addContact(contact);
          });
        } else {
          (contacts as any).isOpen = contacts.isOpen === true ? 1 : 0;
          await addContact(contacts);
        }
      } catch (error) {
        console.log(`updateOrPushContactIDB() error`, error);
      }
    },

    async loadLastMessagesInActiveChat(
      chatId: number,
      fromMsgId?: number,
      qnt?: number
    ): Promise<void> {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let queryByServer = false;
          const contact = await db.contacts.get(chatId);
          if (!contact) {
            reject("Contact not found");
            return;
          }
          if (typeof fromMsgId !== "undefined") {
            const index = contact.Messages.findIndex(
              (message) => message.id == fromMsgId
            );
            if (index === -1) {
              reject("Message not found");
              return;
            }
            let previousMessages = contact.Messages.slice(0, index);
            if (qnt !== undefined) {
              previousMessages = previousMessages.slice(-qnt);
            }

            if (previousMessages.length === 0) {
              // Aqui buscamos direto do servidor para ficar melhor
              previousMessages = await this.queryMessages(
                contact.id_api,
                contact.id,
                {
                  lastMsgId: fromMsgId,
                  direction: "before",
                  quantity: qnt,
                }
              );
              queryByServer = true;
            }

            if (this.openedChat?.id === chatId) {
              const messages = [
                ...this.openedChat.Messages,
                ...previousMessages,
              ];
              messages.sort((a, b) => a.timestamp - b.timestamp);
              this.openedChat!.Messages = messages;

              if (queryByServer) {
                db.contacts.update(
                  chatId,
                  JSON.parse(JSON.stringify({ Messages: messages }))
                );
              }
              resolve();
            } else {
              const messages = [...contact.Messages, ...previousMessages];
              messages.sort((a, b) => a.timestamp - b.timestamp);
              contact!.Messages = messages;
              if (queryByServer) {
                db.contacts.update(
                  chatId,
                  JSON.parse(JSON.stringify({ Messages: messages }))
                );
              }
              resolve();
            }
          } else {
            if (!contact?.Messages || contact.Messages?.length === 0) {
              // Aqui buscamos direto do servidor
              const previousMessages = await this.queryMessages(
                contact.id_api,
                contact.id,
                {
                  lastMsgId: fromMsgId,
                  direction: "before",
                  quantity: qnt,
                }
              );
              queryByServer = true;
              previousMessages.sort((a, b) => a.timestamp - b.timestamp);
              contact!.Messages = previousMessages;
              if (previousMessages.length === 0) {
                reject("NotHaveMoreMessages");
              } else {
                if (queryByServer) {
                  db.contacts.update(
                    chatId,
                    JSON.parse(JSON.stringify({ Messages: previousMessages }))
                  );
                }
                resolve();
              }
            }
          }
        } catch (error: any) {
          reject(error);
          throw new Error(error);
        }
      });
    },
    hasPermission(permission: string): boolean {
      return this.user?.permissions?.includes(permission);
    },
  },
  getters: {
    //nothing
  },
});
