import { api } from "../../service/api";
import { Contact } from "../../types/Contact";

/**
 * Retorna todos os chats abertos
 * Essa função retorna apenas o Contato, não retorna as mensagens
 */
export async function getChats(): Promise<Contact[]> {
  try {
    const { data } = await api.get(`/get-chats`);
    return data || [];
  } catch (error) {
    console.log(error);
    return [] as any;
  }
}
