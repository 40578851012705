import { config } from "../public/config";
import axios from "axios";
import { socket } from "./socket";

const api = axios.create({
  baseURL: config.$api_url,
  timeout: config.timeoutDuration,
  headers: {
    "x-access-token": localStorage.getItem("jwt"),
    Authorization: localStorage.getItem("jwt"),
  },
});
api.interceptors.request.use(function (config) {
  config.headers.socket = socket.id;

  return config;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      localStorage.removeItem("is_admin");
      window.location.reload();
    }
    return error;
  }
);

export { api };
