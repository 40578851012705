import { Contact } from "@/types/Contact";
import { Message } from "@/types/Message";

/**
 * Função que recebe um contato e retorna a última mensagem enviada válida
 * @param contact Contact
 * @param opts isScheduled para apenas mensagens agendadas, do contrario, não inclui mensagens agendadas
 * @returns Uma mensagem ou null
 */
export function getLastMessage(
  contact: Contact,
  opts?: {
    isScheduled: boolean;
  }
): Message | null {
  if (!contact || !contact?.Messages) {
    return null;
  } else {
    const filter = contact.Messages.filter(
      (msg) =>
        (msg.isScheduled === (opts?.isScheduled || false) ||
          !msg.isScheduled) &&
        msg.type !== "chat-finished"
    );
    return filter[filter.length - 1] || null;
  }
}
