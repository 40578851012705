import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "../stores/dataStore";
//import Index from '../components/Index'
import Index from "../components/Index";
//import Archived from '../components/Archived'
//const Archived = () => import('./components/Archived')
//import AudioCallStart from '../components/AudioCallStart'
//import AudioCall from '../components/AudioCall'
//import AudioCallGroup from '../components/AudioCallGroup'
//import AudioCallSingle from '../components/AudioCallSingle'
//import Contacts from '../components/Contacts'
const Contacts = () => import("../components/Contacts/Index");
const Companies = () => import("../components/Companies/Index");
//import ForgotpasswordEmail from '../components/ForgotpasswordEmail'
//import Login from '../components/Login'
const Login = () => import("../components/Login");
//import OtpEmail from '../components/OtpEmail'
//import ResetPassword from '../components/ResetPassword'
//import Settings from '../components/Settings'
const Settings = () => import("../components/Settings");
const UsersSettings = () => import("../components/settings/UsersSettings");
const SystemSettings = () => import("../components/settings/SystemSettings");
const PaymentSettings = () => import("../components/settings/PaymentSettings");
const CustomDataSettings = () =>
  import("../components/settings/CustomDataSettings");
const HolidaysSettings = () =>
  import("../components/settings/HolidaysSettings");
const OpeningHoursSettings = () =>
  import("../components/settings/OpeningHoursSettings");
const ReplysSettings = () => import("../components/settings/ReplysSettings");
const FastReplysSettings = () =>
  import("../components/settings/FastReplysSettings");
const DepartmentsSettings = () =>
  import("../components/settings/DepartmentsSettings");
const TagsSettings = () => import("../components/settings/TagsSettings");
const ChannelSettings = () => import("../components/settings/ChannelSettings");
const IntegrationsSettings = () =>
  import("../components/settings/IntegrationsSettings");
const WhatsAppChannelSettings = () =>
  import("../components/settings/WhatsAppChannelSettings");
const TelegramChannelSettings = () =>
  import("../components/settings/TelegramChannelSettings");
const MessengerChannelSettings = () =>
  import("../components/settings/MessengerChannelSettings");
const InstagramChannelSettings = () =>
  import("../components/settings/InstagramChannelSettings");
const WhatsAppCloudChannelSettings = () =>
  import("../components/settings/WhatsAppCloudChannelSettings");
const NotAvailableSettings = () =>
  import("../components/settings/NotAvailableSettings");
const APISettings = () => import("../components/settings/APISettings");
//import RegisterUser from '../components/RegisterUser'
const RegisterUser = () => import("../components/RegisterUser");
//import Channels from '../components/Channels'
const Channels = () => import("../components/Channels");
const FlowBuilder = () => import("../components/FlowBuilder");
const Suggests = () => import("../components/Suggests");
const Kanban = () => import("../components/Kanban/Index");

/**
 * Admin Routes
 * @returns
 */
const Admin = () => import("../components/SiteAdmin/Index");
const PuppeteerToSocket = () =>
  import("../components/SiteAdmin/Migrations/PuppeteerToSocket.vue");
const SiteAdminUsers = () => import("../components/SiteAdmin/Users/Users.vue");
const EditUser = () => import("../components/SiteAdmin/Users/EditUser.vue");
const Announcements = () =>
  import("../components/SiteAdmin/Settings/Announcements.vue");

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/index",
    name: "indexx",
    component: Index,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/flow",
    name: "flow",
    component: FlowBuilder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sugestoes",
    name: "sugestoes",
    component: Suggests,
    meta: {
      requiresAuth: true,
    },
  },
  /*
    {
      path: '/archived',
      name: 'archived',
      component: Archived,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/audio-call-start',
      name: 'audio-call-start',
      component: AudioCallStart
    },
    {
      path: '/audio-call',
      name: 'audio-call',
      component: AudioCall
    },
    {
      path: '/audiocall-group',
      name: 'audiocall-group',
      component: AudioCallGroup
    },
    {
      path: '/audiocall-single',
      name: 'audiocall-single',
      component: AudioCallSingle
    },*/
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: Companies,
    meta: {
      requiresAuth: true,
    },
  },
  /*
    {
      path: '/forgotpassword-email',
      name: 'forgotpassword-email',
      component: ForgotpasswordEmail
    },*/
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  /*
    {
      path: '/otp-email',
      name: 'otp-email',
      component: OtpEmail
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword
    },*/
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/usuarios",
    name: "users-settings",
    component: UsersSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/sistema",
    name: "system-settings",
    component: SystemSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/assinatura",
    name: "payment-settings",
    component: PaymentSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/campos-personalizados",
    name: "customdata-settings",
    component: CustomDataSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/feriados",
    name: "holidays-settings",
    component: HolidaysSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/horarios",
    name: "opening-hours-settings",
    component: OpeningHoursSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/respostas",
    name: "replys-settings",
    component: ReplysSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/respostas-rapidas",
    name: "fastreplys-settings",
    component: FastReplysSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/setores",
    name: "departments-settings",
    component: DepartmentsSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/tags",
    name: "tags-settings",
    component: TagsSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/canais",
    name: "channel-settings",
    component: ChannelSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/integracoes",
    name: "integrations-settings",
    component: IntegrationsSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/canais/whatsapp",
    name: "whatsapp-settings",
    component: WhatsAppChannelSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/canais/telegram",
    name: "telegram-settings",
    component: TelegramChannelSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/canais/messenger",
    name: "messenger-settings",
    component: MessengerChannelSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/canais/instagram",
    name: "instagram-settings",
    component: InstagramChannelSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/canais/whatsapp-cloud-api",
    name: "whatsapp-cloud-settings",
    component: WhatsAppCloudChannelSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/nao-disponivel",
    name: "not-available-settings",
    component: NotAvailableSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes/api",
    name: "api-settings",
    component: APISettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro",
    name: "cadastro",
    component: RegisterUser,
    meta: { guest: true },
  },
  {
    path: "/canais",
    name: "canais",
    component: Channels,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kanban",
    name: "Kanban",
    component: Kanban,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site-admin",
    name: "admin",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site-admin/index",
    name: "admin",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site-admin/migration/puppeteer-to-socket",
    name: "PuppeteerToSocket",
    component: PuppeteerToSocket,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site-admin/users",
    name: "Site Admin Users",
    component: SiteAdminUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site-admin/edit-user",
    name: "Edit User",
    component: EditUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site-admin/announcements",
    name: "Announcements",
    component: Announcements,
    meta: {
      requiresAuth: true,
    },
  },
  /*
    {
      path: '/status',
      name: 'status',
      component: Status,
      meta: {
        requiresAuth: true
      }
    },
     {
      path: '/video-call',
      name: 'video-call',
      component: VideoCall
    },
   {
      path: '/videocall-group',
      name: 'videocall-group',
      component: VideoCallGroup
    },
    {
      path: '/videocall-single',
      name: 'videocall-single',
      component: VideoCallSingle
    },
    {
      path: '/admin/admob',
      name: 'admob',
      component: Admob,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/blank-page',
      name: 'blank-page',
      component: BlankPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/blocked-users',
      name: 'blocked-users',
      component: BlockedUsers,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/call-history',
      name: 'call-history',
      component: CallHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/coming-soon',
      name: 'coming-soon',
      component: ComingSoon,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/components',
      name: 'components',
      component: Components,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/data-tables',
      name: 'data-tables',
      component: DataTables,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/error',
      name: 'error',
      component: Error,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/firebase-settings',
      name: 'firebase-settings',
      component: FirebaseSettings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/form-basic-inputs',
      name: 'form-basic-inputs',
      component: FormBasicInputs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/form-horizontal',
      name: 'form-horizontal',
      component: FormHorizontal,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/form-input-groups',
      name: 'form-input-groups',
      component: FormInputGroups,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/form-mask',
      name: 'form-mask',
      component: FormMask,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/form-validation',
      name: 'form-validation',
      component: FormValidation,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/form-vertical',
      name: 'form-vertical',
      component: FormVertical,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/general',
      name: 'general',
      component: General,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/group-history',
      name: 'group-history',
      component: GroupHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/login',
      name: 'login-admin',
      component: LoginAdmin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/report-users',
      name: 'report-users',
      component: ReportUsers,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/sinch-settings',
      name: 'sinch-settings',
      component: SinchSettings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/tables-basic',
      name: 'tables-basic',
      component: TablesBasic,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/users',
      name: 'users',
      component: Users,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/vector-maps',
      name: 'vector-maps',
      component: VectorMaps,
      meta: {
        requiresAuth: true
      }
    }
    */
];

export const router = createRouter({
  history: createWebHistory(""),
  linkActiveClass: "active",
  routes,
});
// Meta Handling
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next();
        } else {
          next({ path: "/" });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});
