import { router } from "../router/index";
import ntfy_chat from "../assets/msg_chat.mp3";
import ntfy_group from "../assets/msg_group.mp3";

export function playSoundMessageGroup(data = null) {
  if (router.currentRoute.value.path == "/login") {
    return true;
  }
  if (data == null || !data.fromMe) {
    const audio = new Audio(ntfy_group);
    audio.volume = 0.2;
    audio.play();
  }
}
export function playSoundMessageCtt(data = null) {
  if (router.currentRoute.value.path == "/login") {
    return true;
  }
  if (data == null || !data.fromMe) {
    const audio = new Audio(ntfy_chat);
    audio.volume = 0.3;
    audio.play();
  }
}
