import { defineStore } from "pinia";
import { DrawflowNode } from "@/types/BotFlow";

export interface FlowState {
  modules: any;
  nodes: DrawflowNode["data"];
  module: DrawflowNode;
}
export interface FlowStateActions {
  addModule(): Promise<void>;
}

export const flowStore = defineStore("flow", {
  state: (): FlowState =>
    ({
      modules: [],
      nodes: {} as DrawflowNode["data"],
      module: {} as DrawflowNode,
    } as FlowState),
  actions: {
    setModule(module: DrawflowNode): void {
      this.module = module;
    },
  },
  getters: {
    getNodeItemByKey: (state: FlowState) => {
      return (key: number) => state.module.data.nodes[key];
    },
    getNodeById: (state: FlowState) => {
      return (id: string) =>
        state.module.data.nodes.find((item) => item.id === id);
    },
  },
});
