import { io } from "socket.io-client";
import { config } from "../public/config";

const jwt = localStorage.getItem("jwt");

const socket = io(config.$api_url, {
  auth: {
    token: jwt,
  },
  autoConnect: true,
});

export { socket };
