import { Message } from "../types/Message";
import { errorSwal, receiveCall } from "../helpers/alertSwal";
import { UserState, UserStateActions, useStore } from "../stores/dataStore";
import { Channel } from "../types/Channel";
import { socket } from "./socket";
import { Contact } from "../types/Contact";
import { User } from "../types/User";
import { notifyWide } from "../helpers/notifications";
import { api } from "./api";

function UserStore(): UserState & UserStateActions {
  return useStore() as any;
}

/**
 * Connection errors Socket.IO
 */
socket.on("connect_error", () => {
  UserStore().readyConnection = false;
});
socket.on("connect", () => {
  UserStore().readyConnection = true;
});
/**
 * All the whatsapp server (WPP) set connected offline
 */
socket.on("restartServer", () => {
  UserStore().channels.forEach((channel: Channel) => {
    if (channel.type == "whatsapp") {
      channel.isConnected = false;
    }
  });
});

/**
 * Events handler of Session connection
 * @Session
 */

socket.on("inChat", (sessionName: string) => {
  UserStore().channels.forEach((channel: Channel) => {
    if (channel.session_name == sessionName) {
      channel.isConnected = true;
    }
  });
});
socket
  .on("noConnection", markWhatsAppError)
  .on("desconnectedMobile", markWhatsAppError)
  .on("notLogged", markWhatsAppError)
  .on("qrReadError", markWhatsAppError);
function markWhatsAppError(sessionName: string) {
  UserStore().channels.forEach((channel: Channel) => {
    if (channel.session_name == sessionName) {
      channel.isConnected = false;
    }
  });
}

/**
 * Handler create message on store chat
 */
socket.on("message:new", (event: { message: Message; contact: Contact }) => {
  if (event.message.type == "call_log" && event.message.subtype == "miss") {
    errorSwal("Ligação perdida de " + event.contact.name);
  }
  if (event.message.userIdSend == UserStore().user.id) return;
  UserStore().processAndAddMessage(event.contact, event.message);
});

socket.on("message:bot", (event: { message: Message; contact: Contact }) => {
  UserStore().processAndAddMessage(event.contact, event.message);
});
socket.on(
  "message:user",
  (event: {
    message: Message;
    contact: Contact;
    userId: number;
    type?: "forward";
  }) => {
    UserStore().processAndAddMessage(
      event.contact,
      event.message,
      event.userId,
      event.type
    );
  }
);
/**
 * Update message ack and deleted messages
 */
socket
  .on("message:ack", updateAckMessage)
  .on("message:revoked", updateAckMessage);
function updateAckMessage(event: {
  msgId: string;
  ack: number;
  chatId: string;
}) {
  UserStore().setAck(event.chatId, event.msgId, event.ack);
}

/**
 * Update message reaction
 * This function necessite a upgrade to update message to various contacts
 */
socket.on(
  "message:reaction",
  (event: { message: Message; contactId: number; msgId: string }) => {
    UserStore().editMessage(event.contactId, event.msgId, {
      reactions: `${event.message.reactions}`,
    });
  }
);

/**
 * Update message ack and deleted messages
 */
socket.on("message:update", updateMessage);
function updateMessage(event: { message: Message; contato: Contact }) {
  UserStore().editMessage(event.contato.id, event.message.msgId, {
    isEdited: event.message.isEdited,
    content: event.message.content,
    body: event.message.body,
    dateScheduled: event.message.dateScheduled,
    isScheduled: event.message.isScheduled,
    ack: event.message.ack,
    path: event.message.path,
  });
}

/**
 * Destroy scheduled messages
 */
socket.on("message:destroy", destroyMessage);
function destroyMessage(event: { id: number; contactId: number }) {
  UserStore().destroyMessage(event.id);
}

socket.on("messages:push", pushMessages);
function pushMessages(event: { contact: Contact; messages: Message[] }) {
  const ordering = event.messages.slice().reverse();
  const chat = UserStore().contacts.find(
    (ctt) => ctt.id_api == event.contact.id_api
  );
  if (chat) {
    UserStore().editContact(event.contact.id, {
      Messages: ordering.concat(chat.Messages),
    });
  }
}

/**
 * Call Infos
 */
socket.on(
  "call:incoming",
  (event: {
    id_api: string;
    contactId: number;
    contact: Contact;
    idCall: string;
    isVideo: boolean;
  }) => {
    receiveCall(event.isVideo);

    if (event.isVideo) {
      notifyWide(
        "Recebendo chamada",
        "Você está recebendo uma chamada de video no WhatsApp",
        "https://icons.veryicon.com/png/o/miscellaneous/cloud-call-center/call-up.png"
      );
    } else {
      notifyWide(
        "Recebendo ligação",
        "Você está recebendo uma chamada de voz no WhatsApp",
        "https://icons.veryicon.com/png/o/miscellaneous/cloud-call-center/call-up.png"
      );
    }
  }
);

/**
 * Contacts handler event
 */
socket.on("contact:new", (event: Contact) => {
  const contact = UserStore().getContactById(event.id);
  if (contact !== undefined) {
    UserStore().editContact(event.id, event);
  } else {
    UserStore().addContact(event);
  }
});
socket.on(
  "contact:update",
  (event: {
    contact: Contact;
    message?: Message;
    type: "unreadCount" | "transfer" | "finish";
  }) => {
    UserStore().editContact(event.contact.id, event.contact);
  }
);
socket.on("contact:finish", (event: { contactId: number }) => {
  UserStore().editContact(event.contactId, {
    userIdOpened: 0,
    isOpen: 0 as any,
    departmentId: 0,
    botId: 0,
  });
});

socket.on("contact:revoked", (event: { chatId: string; userId: number }) => {
  const contact = UserStore().getContact(event.chatId);
  if (contact?.id) {
    UserStore().editContact(contact.id, {
      userIdOpened: event.userId,
      isOpen: 1 as any,
      botId: 0,
      departmentId: 0,
    });
  }
});

socket.on(
  "contact:presence",
  (presenseChangedEvent: {
    id: string;
    isOnline: boolean;
    isContact: boolean;
    isGroup: boolean;
    isUser: true;
    shortName: string;
    t: number;
    state: "available" | "unavailable" | "composing" | "recording";
  }) => {
    const contact = UserStore().getContact(presenseChangedEvent.id);
    if (contact) {
      UserStore().editContact(contact.id, {
        isRecording: presenseChangedEvent.state === "recording" ? true : false,
        isOnline: presenseChangedEvent.isOnline,
        isComposing: presenseChangedEvent.state === "composing" ? true : false,
      });
      setTimeout(() => {
        UserStore().editContact(contact.id, {
          isRecording: false,
          isOnline: false,
          isComposing: false,
        });
      }, 10000);
    }
  }
);
/**
 * System events
 */
socket.on("system:labels", (labels: any) => {
  UserStore().labels = labels;
});
socket.on("system:departments", (departments: any) => {
  UserStore().departments = departments;
});
socket.on("system:customData", (customdata: any) => {
  UserStore().config.customdata = customdata;
});
socket.on("system:fastMessages", (fastmessage: any) => {
  for (let i = 0; i < fastmessage.length; i++) {
    const element = fastmessage[i];
    element.msg = JSON.parse(element.msg);
    element.buttons = JSON.parse(element.buttons);
  }
  UserStore().fastmsgs = fastmessage;
});

socket.on("user:renovate-token", (event: { user: User; token: string }) => {
  if (event.user.id === UserStore().user.id) {
    localStorage.setItem("jwt", event.token);
    api.interceptors.request.use(function (config): any {
      config.headers.Authorization = event.token;
      config.headers["x-access-token"] = event.token;
      return config;
    });
    socket.auth = {
      token: event.token,
    };
  }
});

socket.io.on("reconnect", () => {
  UserStore().syncChats();
});

export default {};
