import { db } from "../dbs/db";
import Swal from "sweetalert2";

export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
    } catch (err) {
      reject(err);
    }
  });
}
export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
export async function isDarkMode() {
  const darkMode = localStorage.getItem("darkMode");
  if (darkMode === "enabled") {
    return true;
  } else {
    return false;
  }
}
export function parseVcard(input) {
  const Re1 = /^(version|fn|title|org):(.+)$/i;
  const Re2 = /^([^:;]+);([^:]+):(.+)$/;
  const ReKey = /item\d{1,2}\./;
  const fields = {};

  input.split(/\r\n|\r|\n/).forEach(function (line) {
    let results, key;

    if (Re1.test(line)) {
      results = line.match(Re1);
      key = results[1].toLowerCase();
      fields[key] = results[2];
    } else if (Re2.test(line)) {
      results = line.match(Re2);
      key = results[1].replace(ReKey, "").toLowerCase();

      const meta = {};
      results[2]
        .split(";")
        .map(function (p, i) {
          const match = p.match(/([a-z]+)=(.*)/i);
          if (match) {
            return [match[1], match[2]];
          } else {
            return ["TYPE" + (i === 0 ? "" : i), p];
          }
        })
        .forEach(function (p) {
          meta[p[0]] = p[1];
        });

      if (!fields[key]) fields[key] = [];

      fields[key].push({
        meta: meta,
        value: results[3].split(";"),
      });
    }
  });

  return fields;
}

export function deleteSessionStoragesAndReload(err) {
  if (err?.response?.status === 401) {
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    localStorage.removeItem("is_admin");
    document.location.reload(true);
    db.delete();
  } else {
    Swal.fire(
      "Oops!!",
      "Houve um erro na sua requisição, por favor, tente novamente!",
      "error"
    );
  }
}
