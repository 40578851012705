import Swal from "sweetalert2";
import { getBase64 } from "./funcs";
import sendFileImg from "../assets/img/send-file.png";
import sendAudioImg from "../assets/img/send-audio.png";
import sendVideoImg from "../assets/img/send-video.png";

export function errorSwal(text, buttonText = "Ok") {
  Swal.fire({
    title: "Ooops!",
    text: text,
    icon: "error",
    confirmButtonText: buttonText,
    confirmButtonColor: "#4B0973",
  });
}

export function deleteSwal(
  confirmText = "Seu arquivo foi deletado com sucesso.",
  callback
) {
  Swal.fire({
    title: "Você tem certeza?",
    text: "Não será possível reverter isso.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#4B0973",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, deletar!",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire("Deletado!", confirmText, "success");
      callback(result);
    }
  });
}
export function infoSwal(text) {
  Swal.fire({
    title: "Show!",
    text: text,
    icon: "success",
    confirmButtonText: "Ok",
    confirmButtonColor: "#4B0973",
  });
}

export function receiveCall(isVideo) {
  let text;
  isVideo
    ? (text = "Você está recebendo uma chamada de video no WhatsApp")
    : (text = "Você está recebendo uma chamada de voz no WhatsApp");
  Swal.fire({
    position: "bottom-end",
    icon: "success",
    title: text,
    showConfirmButton: false,
    timer: 3500,
  });
}

export function promptSendDocument(file, type, caption, contact_type) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const input =
        contact_type !== "messenger"
          ? {
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
                placeholder: "Insira aqui a sua legenda",
                defaultValue: caption,
              },
            }
          : {};
      if (type == "image") {
        await getBase64(file).then((base64) => {
          Swal.fire({
            ...input,
            imageUrl: base64,
            imageAlt: "Enviar arquivo",
            showCancelButton: true,
            confirmButtonColor: "#4B0973",
            cancelButtonColor: "#d33",
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar",
          })
            .then((result) => {
              if (result.isConfirmed) {
                resolve({
                  result: result.isConfirmed,
                  caption: contact_type !== "messenger" ? result.value : null,
                  base64: base64,
                });
              } else {
                file = null;
                reject({ result: result.isConfirmed });
              }
            })
            .catch((err) => {
              reject({ result: false });
            });
        });
      } else {
        let sendImg = sendFileImg;
        let titleText = "Enviando documento";
        if (type == "audio")
          (sendImg = sendAudioImg), (titleText = "Enviando áudio");
        if (type == "video")
          (sendImg = sendVideoImg), (titleText = "Enviando vídeo");
        Swal.fire({
          ...input,
          title: titleText,
          imageUrl: sendImg,
          imageAlt: "Enviar arquivo",
          showCancelButton: true,
          confirmButtonColor: "#4B0973",
          cancelButtonColor: "#d33",
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
        })
          .then((result) => {
            if (result.isConfirmed) {
              resolve({
                result: result.isConfirmed,
                caption: result.value,
                base64: "",
              });
            } else {
              reject({ result: result.isConfirmed });
            }
          })
          .catch((err) => {
            reject({ result: false });
          });
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function loading() {
  Swal.fire({
    title: "Por favor, aguarde...",
    timerProgressBar: true,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading();
      /*const b = Swal.getHtmlContainer().querySelector('b')
      var timerInterval = setInterval(() => {
        b.textContent = Swal.getTimerLeft()
      }, 100)*/
    },
    willClose: () => {
      //clearInterval(timerInterval)
    },
    allowOutsideClick: () => {
      const popup = Swal.getPopup();
      popup.classList.remove("swal2-show");
      setTimeout(() => {
        popup.classList.add("animate__animated", "animate__headShake");
      });
      setTimeout(() => {
        popup.classList.remove("animate__animated", "animate__headShake");
      }, 500);
      return false;
    },
  });
}

export function close() {
  Swal.close();
}
